const sizes = {
  xs: {
    fontSize: '0.75rem',
    marginBottom: '0.1rem',
    marginTop: '0.2rem',
  },
  sm: {
    fontSize: '0.85rem',
    marginY: '0.25rem',
  },
  md: {
    fontSize: 'md',
  },
}

export default {
  sizes,
  defaultProps: {
    size: 'sm',
  },
}
