import { z } from 'zod'
import { stereoMixSettingsSchema } from '@customTypes/cloudmix/common/stereoMix'
import { positionSchema } from '@customTypes/cloudmix/common/position'
import { cropSchema } from '@customTypes/cloudmix/common/crop'

export const audioVideoPadSchema = z.object({
  name: z.string(),
  displayName: z.string().optional(),
})
export type AudioVideoPad = z.infer<typeof audioVideoPadSchema>

export const mixerInputVideoSchema = z.object({
  active: z.boolean().default(false),
  alpha: z.number().min(0).max(1).default(1),
  crop: cropSchema.default({
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
  }),
  order: z.number().default(0),
  position: positionSchema.default({
    x: 0,
    y: 0,
    positionType: 'normalised',
  }),
  scale: z.number().min(0).max(2).default(1),
})
export type MixerInputVideo = z.infer<typeof mixerInputVideoSchema>

export const mixerInputAudioSchema = z.object({
  mute: z.boolean().default(true),
  volume: z.number().default(1),
  stereoMix: stereoMixSettingsSchema,
})
export type MixerInputAudio = z.infer<typeof mixerInputAudioSchema>

export const mixerInputStateSchema = z.object({
  audioLevel: z.object({
    channels: z.array(
      z.object({
        decay: z.number(),
        rms: z.number(),
        peak: z.number(),
      })
    ),
    duration: z.number(),
  }),
})
export type MixerInputState = z.infer<typeof mixerInputStateSchema>

export const mixerOutputPadSchema = z.object({
  name: z.string(),
  displayName: z.string(),
})

export const mixerInputPadSchema = audioVideoPadSchema.extend({
  video: mixerInputVideoSchema,
  audio: mixerInputAudioSchema,
  state: mixerInputStateSchema.optional(),
})
export type MixerInputPad = z.infer<typeof mixerInputPadSchema>

const padSchema = z.union([audioVideoPadSchema, mixerInputPadSchema])
export type Pad = z.infer<typeof padSchema>
