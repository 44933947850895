import { useRef, useEffect } from 'react'
import NProgress from 'nprogress'
import Router from 'next/router'

function useNProgressRoute(showAfterMs = 300, options = {}) {
  const timer = useRef<ReturnType<typeof setTimeout> | null>(null)

  function routeChangeStart() {
    if (timer.current !== null) {
      clearTimeout(timer.current)
    }
    // eslint-disable-next-line @typescript-eslint/no-implied-eval
    timer.current = setTimeout(NProgress.start, showAfterMs)
  }

  function routeChangeEnd() {
    if (timer.current !== null) {
      clearTimeout(timer.current)
    }
    NProgress.done()
  }

  useEffect(() => {
    if (options) {
      NProgress.configure(options)
    }

    Router.events.on('routeChangeStart', routeChangeStart)
    Router.events.on('routeChangeComplete', routeChangeEnd)
    Router.events.on('routeChangeError', routeChangeEnd)

    return () => {
      Router.events.off('routeChangeStart', routeChangeStart)
      Router.events.off('routeChangeComplete', routeChangeEnd)
      Router.events.off('routeChangeError', routeChangeEnd)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showAfterMs, options])
}

export default useNProgressRoute
