import { Component, ComponentData } from '@features/projects/lib/base/Component'
import { Pad, PadData } from '@features/projects/lib/base/Pad'
import {
  SRT_MODE_ENUM,
  SrtMode,
} from '@customTypes/cloudmix/common/streamingProtocol'
/* eslint-disable react/sort-comp */

interface SrtDestinationData extends ComponentData {
  location: string
  passphrase: string | null
  streamId: string | null
  mode: SrtMode
  latency: number | null
  input: PadData
  enabled: boolean
}
export class SrtDestination extends Component {
  type = 'srtDestination'

  location: string

  passphrase: string | null

  latency: number | null

  streamId: string | null

  mode: SrtMode

  input: Pad

  enabled: boolean

  constructor(data: SrtDestinationData) {
    super(data)
    this.location = data.location
    this.passphrase = data.passphrase
    this.streamId = data.streamId
    this.latency = data.latency
    this.mode = data.mode
    this.input = new Pad(data.input)
    this.enabled = data.enabled
  }

  toData() {
    const commonData = super.toData()
    return {
      ...commonData,
      type: this.getType(),
      location: this.location,
      mode: this.mode,
      input: this.input.toData(),
      enabled: this.enabled,
      ...(this.streamId && { streamId: this.streamId }),
      ...(this.passphrase && { passphrase: this.passphrase }),
      ...(this.latency && { latency: this.latency }),
    }
  }

  static fromData(data) {
    return new SrtDestination(data)
  }

  static create() {
    const data = {
      location: '',
      streamId: null,
      passphrase: null,
      latency: null,
      mode: SRT_MODE_ENUM.CALLER,
      input: new Pad(),
      enabled: false,
    }
    return new SrtDestination(data)
  }

  patch(data: Partial<SrtDestinationData>) {
    super.patch(data)
    const { input, ...rest } = data
    this.input.patch(input as Pad)
    Object.assign(this, { ...rest })
  }
}
