export const UPLOAD_PART_SIZE = 100000000

type FileType = 'image' | 'video' | 'audio'

const validFileTypes: Record<FileType, string[]> = {
  image: ['image/gif', 'image/jpeg', 'image/png'],
  video: ['video/mp4', 'video/quicktime'],
  audio: ['audio/mpeg', 'audio/ogg', 'audio/wav'],
}

export const validateFileType = (file: File): boolean => {
  const fileType = file.type.split('/')[0]
  if (validFileTypes[fileType])
    return validFileTypes[fileType].includes(file.type)

  return false
}

export const splitFile = (file: File): File[] => {
  const bytesPerChunk = 100000000
  const chunks: File[] = []

  if (file.size < bytesPerChunk) {
    chunks.push(file)
    return chunks
  }

  let totalBytes = 0
  while (totalBytes < file.size) {
    const chunkBlob = file.slice(totalBytes, totalBytes + bytesPerChunk)
    // Ensure each chunk retains the original file name, type, and last modified
    const chunkFile = new File([chunkBlob], file.name, {
      type: file.type,
      lastModified: file.lastModified,
    })
    chunks.push(chunkFile)
    totalBytes += bytesPerChunk
  }
  return chunks
}

export const getAcceptedFileTypes = (type: FileType): string =>
  validFileTypes[type].join(',')
