import { Component, ComponentData } from '@features/projects/lib/base/Component'
import { Pad, PadData } from '@features/projects/lib/base/Pad'
/* eslint-disable react/sort-comp */
export interface DisplayData extends ComponentData {
  lobbyPassword: string | null
  tokenExpiry: number
  inputs: PadData[]
}

export class Display extends Component {
  type = 'display'

  lobbyPassword: string | null

  tokenExpiry: number

  inputs: Pad[]

  constructor(data: DisplayData) {
    super(data)
    this.lobbyPassword = data.lobbyPassword
    this.tokenExpiry = data.tokenExpiry
    this.inputs = data.inputs.map((input) => new Pad(input))
  }

  addInput(name?: string, displayName?: string): Pad {
    const input = new Pad({
      name,
      displayName,
    })

    this.inputs = [...this.inputs, input]

    return input
  }

  removeInput(name: string) {
    this.inputs = this.inputs.filter((input) => input.name !== name)
  }

  toData() {
    const commonData = super.toData()
    return {
      ...commonData,
      type: this.getType(),
      lobbyPassword: this.lobbyPassword,
      inputs: this.inputs.map((input) => input.toData()),
    }
  }

  static fromData(data) {
    return new Display(data)
  }

  static create() {
    const data = {
      lobbyPassword: null,
      tokenExpiry: 300,
      inputs: [],
    }
    return new Display(data)
  }

  patch(data: Partial<DisplayData>) {
    super.patch(data)
    const { inputs, ...rest } = data
    if (inputs && Array.isArray(inputs)) {
      this.inputs.forEach((input, index) => {
        if (inputs[index]) {
          input.patch(inputs[index])
        }
      })
    }
    Object.assign(this, { rest })
  }
}
