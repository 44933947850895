import { createEntityAdapter, createSlice } from '@reduxjs/toolkit'
import { RootState } from '@lib/store'
import { ConnectionStateEnum } from '@hooks/useWebRTCMediaServer/mediasoupOrchestrator/ConnectionState'
import { WorkflowSessionId } from '@customTypes/cloudmix/workflow'

export interface Peer {
  id: string
  workflowSessionId: string
  streams: string[]
  connectionState: ConnectionStateEnum
  incomingDataChannels: string[]
  outgoingDataChannels: string[]
}

export const peersAdapter = createEntityAdapter<Peer>()

export const ProjectWebRTCPeerSlice = createSlice({
  name: 'projectWebRTCPeer',
  initialState: peersAdapter.getInitialState(),
  reducers: {
    addPeer: peersAdapter.addOne,
    removePeer: peersAdapter.removeOne,
    addManyPeers: peersAdapter.addMany,
    updatePeer: peersAdapter.updateOne,
    addStreamToPeer: (state, action) => {
      const { peerId, streamId } = action.payload
      const peer = state.entities[peerId]
      if (peer) {
        if (!peer.streams.includes(streamId)) {
          peer.streams.push(streamId)
        }
      }
    },
    removeStreamFromPeer: (state, action) => {
      const { peerId, streamId } = action.payload
      const peer = state.entities[peerId]
      if (peer) {
        peer.streams = peer.streams.filter((id) => id !== streamId)
      }
    },
    addIncomingDataChannelToPeer: (state, action) => {
      const { peerId, dataChannelId } = action.payload
      const peer = state.entities[peerId]
      if (peer) {
        peer.incomingDataChannels.push(dataChannelId)
      }
    },
    removeIncomingDataChannelFromPeer: (state, action) => {
      const { peerId, dataChannelId } = action.payload
      const peer = state.entities[peerId]
      if (peer) {
        peer.incomingDataChannels = peer.incomingDataChannels.filter(
          (id) => id !== dataChannelId
        )
      }
    },
    addOutgoingDataChannelToPeer: (state, action) => {
      const { peerId, dataChannelId } = action.payload
      const peer = state.entities[peerId]
      if (peer) {
        peer.outgoingDataChannels.push(dataChannelId)
      }
    },
    removeOutgoingDataChannelFromPeer: (state, action) => {
      const { peerId, dataChannelId } = action.payload
      const peer = state.entities[peerId]
      if (peer) {
        peer.outgoingDataChannels = peer.outgoingDataChannels.filter(
          (id) => id !== dataChannelId
        )
      }
    },
  },
})

export default ProjectWebRTCPeerSlice.reducer

export const {
  addPeer,
  removePeer,
  addManyPeers,
  updatePeer,
  addStreamToPeer,
  removeStreamFromPeer,
  addIncomingDataChannelToPeer,
  removeIncomingDataChannelFromPeer,
  addOutgoingDataChannelToPeer,
  removeOutgoingDataChannelFromPeer,
} = ProjectWebRTCPeerSlice.actions

const sliceSelectors = peersAdapter.getSelectors<RootState>(
  (state) => state.projectWebRTCPeer
)

export const selectPeers = sliceSelectors.selectAll

export const selectPeerById = (state: RootState, peerId: string) =>
  sliceSelectors.selectById(state, peerId)

export const selectPeersByWorkflowSessionId = (
  state: RootState,
  workflowSessionId: WorkflowSessionId
) =>
  sliceSelectors
    .selectAll(state)
    .filter((peer: Peer) => peer.workflowSessionId === workflowSessionId)

export const selectPeerByStreamId = (state: RootState, streamId: string) =>
  sliceSelectors
    .selectAll(state)
    .find((peer: Peer) => peer.streams.includes(streamId))
