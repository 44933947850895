import { decodeBase64, encodeBase64 } from '@lib/firebase/encoding'
import { cookieConfig } from './config'

export const getCookie = (name: string): string | null => {
  const cookies = document.cookie.split(';')
  let result = null

  cookies.forEach((cookie) => {
    const trimmedCookie = cookie.trim()
    if (trimmedCookie.startsWith(`${name}=`) && !result) {
      const encodedValue = trimmedCookie.substring(name.length + 1)
      result = decodeBase64(encodedValue)
    }
  })

  return result
}

export const setCookie = (name: string, value: string): void => {
  const encodedValue = encodeBase64(value)
  const { maxAge, path, sameSite, secure } = cookieConfig
  document.cookie = `${name}=${encodedValue}; ${
    maxAge ? `Max-Age=${maxAge / 1000};` : ''
  } Path=${path}; SameSite=${sameSite}; ${secure ? 'Secure' : ''}`
}

export const deleteCookie = (name: string): void => {
  document.cookie = `${name}=; Max-Age=${new Date(0).toUTCString()}; Path=${
    cookieConfig.path
  }; SameSite=${cookieConfig.sameSite};`
}
