import { Component, ComponentData } from '@features/projects/lib/base/Component'
import { Pad, PadData } from '@features/projects/lib/base/Pad'

/* eslint-disable react/sort-comp */
export interface ContributorData extends ComponentData {
  lobbyPassword: string | null
  tokenExpiry: number
  inputs: PadData[]
  outputs: PadData[]
}

export class Contributor extends Component {
  type = 'contributor'

  lobbyPassword: string | null

  tokenExpiry: number

  inputs: Pad[]

  outputs: Pad[]

  constructor(data: ContributorData) {
    super(data)
    this.lobbyPassword = data.lobbyPassword
    this.tokenExpiry = data.tokenExpiry
    this.inputs = data.inputs.map((input) => new Pad(input))
    this.outputs = data.outputs.map((output) => new Pad(output))
  }

  addInput(name?: string, displayName?: string): Pad {
    const input = new Pad({
      name,
      displayName,
    })

    this.inputs = [...this.inputs, input]

    return input
  }

  removeInput(name: string) {
    this.inputs = this.inputs.filter((input) => input.name !== name)
  }

  addOutput(name?: string, displayName?: string): Pad {
    const output = new Pad({
      name: name ?? `output${this.outputs.length + 1}`,
      displayName: displayName ?? `Output ${this.outputs.length + 1}`,
    })

    this.outputs = [...this.outputs, output]

    return output
  }

  removeOutput(name: string) {
    this.outputs = this.outputs.filter((output) => output.name !== name)
  }

  toData() {
    const commonData = super.toData()
    return {
      ...commonData,
      type: this.getType(),
      lobbyPassword: this.lobbyPassword,
      inputs: this.inputs.map((input) => input.toData()),
      outputs: this.outputs.map((output) => output.toData()),
    }
  }

  static fromData(data) {
    return new Contributor(data)
  }

  static create() {
    const data = {
      lobbyPassword: null,
      tokenExpiry: 300,
      inputs: [],
      outputs: [new Pad()],
    }
    return new Contributor(data)
  }

  patch(data: Partial<ContributorData>) {
    super.patch(data)
    const { inputs, outputs, ...rest } = data
    if (inputs && Array.isArray(inputs)) {
      this.inputs.forEach((input, index) => {
        if (inputs[index]) {
          input.patch(inputs[index])
        }
      })
    }
    if (outputs && Array.isArray(outputs)) {
      this.outputs.forEach((output, index) => {
        if (outputs[index]) {
          output.patch(outputs[index])
        }
      })
    }
    Object.assign(this, { rest })
  }
}
