import { Pad, PadData } from '@features/projects/lib/base/Pad'
/* eslint-disable react/sort-comp */

export interface MixerPadData extends PadData {
  video?: {
    active: boolean
    alpha: number
    order: number
    scale: number
    crop: {
      left: number
      top: number
      right: number
      bottom: number
    }
    position: {
      x: number
      y: number
      positionType: 'absolute' | 'normalised'
    }
  }
  audio?: {
    mute: boolean
    volume: number
    stereoMix: {
      leftToRight: number
      leftToLeft: number
      rightToLeft: number
      rightToRight: number
    }
  }
}
export class MixerPad extends Pad {
  video: {
    active: boolean
    alpha: number
    order: number
    scale: number
    crop: {
      left: number
      top: number
      right: number
      bottom: number
    }
    position: {
      x: number
      y: number
      positionType: 'absolute' | 'normalised'
    }
  }

  audio: {
    mute: boolean
    volume: number
    stereoMix: {
      leftToRight: number
      leftToLeft: number
      rightToLeft: number
      rightToRight: number
    }
  }

  constructor(data) {
    super(data)
    this.video = data.video ?? {
      active: false,
      alpha: 1,
      order: 0,
      scale: 1,
      crop: {
        left: 0,
        top: 0,
        right: 0,
        bottom: 0,
      },
      position: {
        x: 0,
        y: 0,
        positionType: 'absolute',
      },
    }
    this.audio = data.audio ?? {
      mute: true,
      volume: 1,
      stereoMix: {
        leftToRight: 0,
        leftToLeft: 1,
        rightToLeft: 0,
        rightToRight: 1,
      },
    }
  }

  toData() {
    const commonData = super.toData()
    return {
      ...commonData,
      video: this.video,
      audio: this.audio,
    }
  }

  patch(data: Partial<MixerPadData>) {
    super.patch(data)
    const { video, audio } = data
    if (video) {
      this.video = { ...this.video, ...video }
    }
    if (audio) {
      this.audio = { ...this.audio, ...audio }
    }
    this.video = { ...this.video, ...video }
    this.audio = { ...this.audio, ...audio }
  }
}
