import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { FeatureDetails, MeObject } from '../types'
import { getClientTenantAuth } from '../../../lib/firebase/getClientTenantAuth'

interface UpdateProfileRequest {
  primaryOrgDisplayName: string
  firstName: string
  lastName: string
  email: string
}

export const userManagementApi = createApi({
  reducerPath: 'userManagementApi',
  tagTypes: [
    'User',
    'Org',
    'OrgFeatures',
    'InvitationsIssued',
    'InvitationsReceived',
    'OrgMembers',
    'Permissions',
    'Features',
    'ApiKey',
    'PermissionsRules',
  ],
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.NEXT_PUBLIC_USER_MANAGEMENT_API_URI}/api/`,
    prepareHeaders: async (headers) => {
      // Get token from cookie of firebase user?
      const auth = getClientTenantAuth()
      if (auth) {
        await auth?.authStateReady()
        const token = await auth?.currentUser?.getIdToken()
        if (token) {
          headers.set('Authorization', `GipIdToken ${token}`)
        }
      }

      return headers
    },
  }),
  endpoints: (build) => ({
    updateProfile: build.mutation<null, UpdateProfileRequest>({
      query: (data) => ({
        url: '/users/legacyUpdateProfile',
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['User'],
    }),
    me: build.query<MeObject, null>({
      query: () => 'legacyClientInfo/me',
      providesTags: [{ type: 'User' }],
    }),
    updateEmail: build.mutation({
      query: () => ({
        url: 'users/UpdateEmailAddress',
        method: 'POST',
      }),
      invalidatesTags: [{ type: 'User' }],
    }),
    registerUser: build.mutation({
      query: ({ ...body }) => ({
        url: 'users/RegisterUser',
        method: 'POST',
        body,
      }),
      invalidatesTags: [{ type: 'User' }],
    }),
    features: build.query<FeatureDetails[], null>({
      query: () => 'feature',
      providesTags: [{ type: 'Features' }],
    }),
  }),
})

export const {
  useMeQuery,
  useUpdateProfileMutation,
  useUpdateEmailMutation,
  useRegisterUserMutation,
  useFeaturesQuery,
} = userManagementApi
