import { z } from 'zod'
import { Dimension } from '@customTypes/cloudmix/common/index'
import { toTwoDecimalPlaces } from '../../../utils/utils'

export const POSITION_TYPE = {
  normalised: 'normalised',
  absolute: 'absolute',
} as const

export const absolutePositionSchema = z.object({
  x: z.number(),
  y: z.number(),
  positionType: z.literal(POSITION_TYPE.absolute),
})

export const normalisedPositionSchema = z.object({
  x: z.number().min(-1).max(1),
  y: z.number().min(-1).max(1),
  positionType: z.literal(POSITION_TYPE.normalised),
})

export const positionSchema = z.discriminatedUnion('positionType', [
  absolutePositionSchema,
  normalisedPositionSchema,
])
export type Position = z.infer<typeof positionSchema>

export const transformToNormalPosition = (
  baseDimensions: Dimension,
  position: Position
) => {
  const { x, y } = position
  const { width, height } = baseDimensions

  if (position.positionType === POSITION_TYPE.normalised) {
    return {
      x: toTwoDecimalPlaces(x / width),
      y: toTwoDecimalPlaces(y / height),
    }
  }

  return {
    x,
    y,
  }
}

export const transformFromNormalPosition = (
  baseDimensions: Dimension,
  position: Position
) => {
  const { x, y } = position
  const { width, height } = baseDimensions

  if (position.positionType === POSITION_TYPE.absolute) {
    return {
      x,
      y,
    }
  }

  return {
    x: x * width,
    y: y * height,
  }
}
