import { eachDayOfInterval, format, intervalToDuration } from 'date-fns'
import { z } from 'zod'

export const getBaseUri = () =>
  process.env.NEXT_PUBLIC_SITE_URL ?? process.env.NEXT_PUBLIC_VERCEL_URL

export function getDefaults<T extends z.ZodTypeAny>(
  schema: z.AnyZodObject | z.ZodEffects<any>
): z.infer<T> {
  // Directly handle ZodEffect instances to extract the inner schema
  function handleZodEffects(s: z.ZodTypeAny): z.ZodTypeAny {
    if (s instanceof z.ZodEffects) {
      // eslint-disable-next-line no-underscore-dangle
      return handleZodEffects(s._def.schema) // Dive into the inner schema if it's wrapped in ZodEffects
    }
    return s
  }

  // Function to get default value from a schema
  function getDefaultValue(s: z.ZodTypeAny): unknown {
    const newSchema = handleZodEffects(s) // Handle ZodEffects to get the actual schema

    if (newSchema instanceof z.ZodDefault) {
      // eslint-disable-next-line no-underscore-dangle
      return newSchema._def.defaultValue() // Get default value for ZodDefault instances
    }
    if (newSchema instanceof z.ZodArray) {
      return [] // Default for arrays
    }
    if (newSchema instanceof z.ZodString) {
      return '' // Default for strings
    }
    if (newSchema instanceof z.ZodNumber) {
      return 0 // Default for numbers
    }
    if (newSchema instanceof z.ZodObject) {
      return getDefaults(newSchema) // Recursive call for objects
    }
    // Fallback for schemas without a simple default (e.g., ZodUnion, ZodEnum)
    return undefined
  }

  // If the input is a ZodObject, process its shape to extract defaults
  if (schema instanceof z.ZodObject) {
    return Object.fromEntries(
      Object.entries(schema.shape).map(([key, value]) => [
        key,
        getDefaultValue(value as z.ZodTypeAny),
      ])
    ) as z.infer<T>
  }

  // Fallback for non-object schemas (though your use case might not need this)
  return undefined as z.infer<T>
}

export const getReadableFileSizeString = (fileSizeInBytes: number): string => {
  let i = -1
  const byteUnits = [
    'kbps',
    'Mbps',
    'Gbps',
    'Tbps',
    'Pbps',
    'Ebps',
    'Zbps',
    'Ybps',
  ]
  do {
    // eslint-disable-next-line no-param-reassign
    fileSizeInBytes /= 1024
    // eslint-disable-next-line no-plusplus
    i++
  } while (fileSizeInBytes > 1024)

  return Math.max(fileSizeInBytes, 0.1).toFixed(1) + byteUnits[i]
}

export const nestedIterator = (obj, fn) => {
  Object.keys(obj).forEach((key) => {
    fn(obj, key)
    if (typeof obj[key] === 'object' && obj[key] !== null) {
      nestedIterator(obj[key], fn)
    }
  })
}

export const pluralize = (count, noun, plural = null) =>
  `${count} ${count !== 1 ? plural ?? `${noun}s` : noun}`

export function pad(num: number) {
  return num.toString().padStart(2, '0')
}

export const formatHHMMSS = (seconds: number): string => {
  const duration = intervalToDuration({ start: 0, end: seconds * 1000 })
  const ss = duration?.seconds ? pad(duration.seconds) : '00'
  const mm = duration?.minutes ? pad(duration.minutes) : '00'
  const hh = duration?.hours ? pad(duration.hours) : '00'
  return `${hh}:${mm}:${ss}`
}

export const convertTimeStringToSeconds = (timeString: string) => {
  const [hours, minutes, seconds] = timeString.split(':')
  return +hours * 60 * 60 + +minutes * 60 + +seconds
}

export const bytesToGbs = (bytes: number) =>
  +(bytes / 1024 / 1024 / 1024).toFixed(2)

export const secondsToHoursWithTwoFractionDigits = (seconds) =>
  +(seconds / 3600).toFixed(2)

export const secondsToMinutesWithTwoFractionDigits = (seconds) =>
  +(seconds / 60).toFixed(2)

export const isDate = (date: any): date is Date => date instanceof Date

export function getValueOrDefault<T>(
  value: T | undefined | null,
  defaultValue: T
): T {
  if (typeof value === 'string') {
    return value.trim() !== '' ? value : defaultValue
  }
  return value ?? defaultValue
}

export const toTwoDecimalPlaces = (num: number) => Math.round(num * 100) / 100
