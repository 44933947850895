import { EmailAddress } from 'src/types'
import { userManagementApi } from '../../users/api'
import {
  Features,
  IssuedInvitation,
  ReceivedInvitation,
  OrgId,
  MemberRoles,
  Org,
  OrgMember,
  AuthenticationResponse,
} from '../types'

interface OrgIdAndEmailAddress {
  orgId: OrgId
  emailAddress: EmailAddress
}

interface UpdateOrgMemberRequest {
  orgId: OrgId
  memberEmailAddress: EmailAddress
  role: MemberRoles
}

export const organisationApi = userManagementApi.injectEndpoints({
  endpoints: (build) => ({
    getOrg: build.query<Org, OrgId>({
      query: (id) => `org/${id}`,
      providesTags: (_result, error, args) => [{ type: 'Org', id: args }],
    }),
    listOrgs: build.query<Org[], null>({
      query: () => 'org',
      providesTags: [{ type: 'Org', id: 'LIST' }],
    }),
    getFeatures: build.query<Features, string>({
      query: (orgId) => `org/${orgId}/features`,
      transformResponse: (response: { name: string }[]) =>
        response.reduce(
          (features, nextFeature) => ({
            ...features,
            [nextFeature.name]: true,
          }),
          {}
        ),
      providesTags: (_result, error, args) => [
        { type: 'OrgFeatures', id: args },
      ],
    }),
    getInvitationsIssued: build.query<IssuedInvitation[], OrgId>({
      query: (orgId) => `org/${orgId}/InvitationsIssued`,
      providesTags: (_result, error, args) => [
        { type: 'InvitationsIssued', id: args },
      ],
    }),
    getInvitationsReceived: build.query<ReceivedInvitation[], null>({
      query: () => `org/InvitationsReceived`,
    }),
    sendInvite: build.mutation<null, OrgIdAndEmailAddress>({
      query: ({ orgId, emailAddress }) => ({
        url: `/org/${orgId}/Invitation?inviteeEmailAddress=${emailAddress}`,
        method: 'POST',
      }),
      invalidatesTags: (_result, error, args) => [
        { type: 'InvitationsIssued', id: args.orgId },
      ],
    }),
    deleteInvitation: build.mutation<void, OrgIdAndEmailAddress>({
      query: ({ orgId, emailAddress }) => ({
        url: `/org/${orgId}/Invitation/${emailAddress}`,
        method: 'DELETE',
      }),
      invalidatesTags: (_result, error, args) => [
        { type: 'InvitationsIssued', id: args.orgId },
      ],
    }),
    acceptInvitation: build.mutation<null, OrgId>({
      query: (orgId) => ({
        url: `/org/${orgId}/Invitation/Accept`,
        method: 'POST',
      }),
      invalidatesTags: (_result, error, args) => [
        { type: 'InvitationsReceived', id: args },
        { type: 'OrgMembers', id: args },
        { type: 'Org', id: args },
      ],
    }),
    getMembers: build.query<OrgMember[], OrgId>({
      query: (orgId) => ({
        url: `/org/${orgId}/Members`,
        method: 'GET',
      }),
      providesTags: (_result, error, args) => [
        { type: 'OrgMembers', id: args },
      ],
    }),
    removeMemberFromOrg: build.mutation<null, OrgIdAndEmailAddress>({
      query: ({ orgId, emailAddress }) => ({
        url: `/org/${orgId}/Member/${emailAddress}`,
        method: 'DELETE',
      }),
      invalidatesTags: (_result, error, args) => [
        { type: 'OrgMembers', id: args.orgId },
      ],
    }),
    leaveOrg: build.mutation<null, OrgId>({
      query: (orgId) => ({
        url: `/org/${orgId}/Member/`,
        method: 'DELETE',
      }),
      invalidatesTags: (_result, error, args) => [
        { type: 'OrgMembers', id: args },
        { type: 'Org', id: 'args' },
        { type: 'Org', id: 'LIST' },
      ],
    }),
    authenticate: build.query<AuthenticationResponse, { orgId: string }>({
      query: ({ orgId }) => ({
        url: `authenticate`,
        method: 'GET',
        params: { orgId },
      }),
      providesTags: (_result, error, { orgId }) => [
        { type: 'PermissionsRules', id: orgId },
      ],
    }),
    updateOrgMember: build.mutation<null, UpdateOrgMemberRequest>({
      query: ({ orgId, memberEmailAddress, role }) => ({
        url: `/org/${orgId}/Member/${memberEmailAddress}`,
        method: 'PATCH',
        body: { role },
      }),
      invalidatesTags: (_result, error, args) => [
        { type: 'OrgMembers', id: args.orgId },
      ],
    }),
  }),
  overrideExisting: false,
})

export const {
  useGetOrgQuery,
  useListOrgsQuery,
  useGetFeaturesQuery,
  useGetInvitationsReceivedQuery,
  useGetInvitationsIssuedQuery,
  useGetMembersQuery,
  useRemoveMemberFromOrgMutation,
  useLeaveOrgMutation,
  useAcceptInvitationMutation,
  useDeleteInvitationMutation,
  useSendInviteMutation,
  useAuthenticateQuery,
  useUpdateOrgMemberMutation,
} = organisationApi
