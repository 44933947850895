import { PermissionRule } from './PermissionRule'
import { PermissionType } from './PermissionType'
import { Permission } from './Permission'

/* eslint-disable no-bitwise,no-plusplus */
export class PermissionsUtil {
  private static readonly PERMISSION_RULE_WILDCARD = '*'

  public static permissionIsGranted(
    permission: Permission,
    permissionRules: PermissionRule[]
  ): boolean {
    const denyRules = permissionRules.filter(
      (rule) => rule.type === PermissionType.Deny
    )
    const allowRules = permissionRules.filter(
      (rule) => rule.type === PermissionType.Allow
    )

    const isDenied = denyRules.some((denyRule) =>
      PermissionsUtil.permissionMatchesRule(permission, denyRule)
    )

    if (isDenied) {
      return false
    }

    const isAllowed = allowRules.some((allowRule) =>
      PermissionsUtil.permissionMatchesRule(permission, allowRule)
    )

    return isAllowed
  }

  private static permissionMatchesRule(
    permission: Permission,
    permissionRule: PermissionRule
  ): boolean {
    // Check if the operations of the permission are a subset of the rule's operations
    if (
      (permission.operations & permissionRule.operations) !==
      permission.operations
    ) {
      return false
    }
    // Check if the resource pattern includes the resource ID
    return PermissionsUtil.resourcePatternIncludes(
      permissionRule.resourcePattern,
      permission.resourceId
    )
  }

  private static resourcePatternIncludes(
    outerPattern: string[],
    innerPattern: string[]
  ): boolean {
    const outerPatternLength = outerPattern.length
    const innerPatternLength = innerPattern.length

    for (let i = 0; true; i++) {
      // We have reached a wildcard component in the outer pattern, and all previous
      // components were equal, so the outer pattern includes the inner pattern
      if (
        i === outerPatternLength - 1 &&
        outerPattern[i] === PermissionsUtil.PERMISSION_RULE_WILDCARD
      ) {
        return true
      }
      // We have finished processing both patterns. They are equal in length, and all of their
      // components were equal, so they are the same pattern, and so the outer pattern
      // includes the inner pattern.
      if (i >= outerPatternLength && i >= innerPatternLength) {
        return true
      }
      // We have finished processing the outer pattern. The outer pattern does not have a
      // wildcard component, and the inner pattern has a component after the end of the outer
      // pattern, so the outer pattern does not include the inner pattern.
      if (i >= outerPatternLength) {
        return false
      }
      // We have finished processing the inner pattern. The outer pattern has a non-wildcard
      // component after the end of the inner pattern, so the outer pattern does not include
      // the inner pattern.
      if (i >= innerPatternLength) {
        return false
      }
      // The current components of the outer and inner patterns are different, and the outer
      // pattern component is not a wildcard, so the outer pattern does not include the inner
      // pattern
      if (outerPattern[i] !== innerPattern[i]) {
        return false
      }
      // The current components of the outer and inner patterns are the same, so we continue
      // to the next pair of components
    }
  }
}
/* eslint-enable no-bitwise,no-plusplus */
