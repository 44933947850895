import { mediaProjectsApi } from '@features/mediaProjectsApi/api'
import { createSelector } from '@reduxjs/toolkit'
import { RootState } from '@lib/store'
import { useMemo } from 'react'
import useAppSelector from '@hooks/useAppSelector'
import {
  ProjectId,
  CreateProject,
  ProjectDetails,
  UpdateProject,
  WithProjectId,
  StartProject,
  GetProjectLobby,
  ListProjectsResponse,
} from '@customTypes/cloudmix/projects'
import {
  Workflow as WorkflowType,
  WorkflowSessionId,
} from '@customTypes/cloudmix/workflow'
import { Workflow } from '@features/projects/lib/base/Workflow'
import { OrgId } from '@features/organisations/types'
import {
  PaginationRequest,
  paginationRequestSchema,
} from '@customTypes/cloudmix/api'

const baseUri = `${process.env.NEXT_PUBLIC_MEDIA_LIBRARY_API_URI}/`

export const projectApi = mediaProjectsApi.injectEndpoints({
  endpoints: (build) => ({
    getProject: build.query<ProjectDetails, ProjectId>({
      query: (projectId) => ({
        url: `${baseUri}projects/${projectId}`,
        method: `GET`,
      }),
      keepUnusedDataFor: 10,
      providesTags: (result, _error, arg) => [{ type: 'Project', id: arg }],
    }),
    listProject: build.query<ListProjectsResponse, PaginationRequest>({
      query: ({ pageIndex = 0, pageSize = 10 }) => {
        const params = paginationRequestSchema.parse({
          pageIndex,
          pageSize,
        })

        return {
          url: `${baseUri}projects`,
          method: 'GET',
          params,
        }
      },
      providesTags: (result) =>
        result
          ? [
              ...result.items.map((val) => ({
                type: 'Project' as const,
                id: val.projectId,
              })),
              {
                type: 'Project',
                id: 'PARTIAL-LIST',
              },
            ]
          : [{ type: 'Project', id: 'PARTIAL-LIST' }],
    }),
    updateProject: build.mutation<void, WithProjectId<UpdateProject>>({
      query: ({ projectId, ...body }) => ({
        url: `${baseUri}projects/${projectId}`,
        method: 'PATCH',
        body: {
          ...body,
        },
      }),
      invalidatesTags: (result, _error, arg) => [
        { type: 'Project', id: arg.projectId },
      ],
    }),
    updateProjectNameAndDescription: build.mutation<
      void,
      WithProjectId<Pick<UpdateProject, 'description' | 'name'>>
    >({
      query: ({ projectId, name, description }) => ({
        url: `${baseUri}projects/${projectId}`,
        method: 'PATCH',
        body: {
          name,
          description,
        },
      }),
      async onQueryStarted(
        { projectId, ...patch },
        { dispatch, queryFulfilled, getState }
      ) {
        const patchResult = dispatch(
          projectApi.util.updateQueryData('getProject', projectId, (draft) => {
            Object.assign(draft, patch)
          })
        )

        const listProjectArgs = projectApi.util.selectCachedArgsForQuery(
          getState(),
          'listProject'
        )

        const listProjectPatches = listProjectArgs.map((arg) =>
          dispatch(
            projectApi.util.updateQueryData(
              'listProject',
              arg,
              (draft: ListProjectsResponse) => {
                const selectedProject = draft.items.find(
                  (project) => project.projectId === projectId
                )
                if (selectedProject) {
                  Object.assign(selectedProject, patch)
                }
              }
            )
          )
        )

        try {
          await queryFulfilled
        } catch {
          patchResult.undo()
          listProjectPatches.forEach((patchRes) => patchRes.undo())
        }
      },
    }),
    updateProjectWorkflow: build.mutation<
      void,
      WithProjectId<{ workflow: WorkflowType }>
    >({
      query: ({ projectId, workflow }) => ({
        url: `${baseUri}projects/${projectId}`,
        method: 'PATCH',
        body: {
          workflow,
        },
      }),
      invalidatesTags: (result, _error, arg) => [
        { type: 'Project', id: arg.projectId },
      ],
    }),
    deleteProject: build.mutation<null, ProjectId>({
      query: (projectId) => ({
        url: `${baseUri}projects/${projectId}`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, _error, arg) => [
        { type: 'Project', id: arg },
        { type: 'Project', id: 'PARTIAL-LIST' },
      ],
    }),
    createProject: build.mutation<ProjectId, CreateProject>({
      query: (project) => ({
        url: `${baseUri}projects`,
        method: 'POST',
        body: project,
      }),
      invalidatesTags: () => [{ type: 'Project', id: 'PARTIAL-LIST' }],
    }),
    startProject: build.mutation<null, WithProjectId<StartProject>>({
      query: ({ projectId, ...body }) => ({
        url: `${baseUri}projects/${projectId}/start`,
        method: 'POST',
        body: {
          ...body,
        },
      }),
      invalidatesTags: (result, _error, arg) => [
        {
          type: 'Project',
          id: arg.projectId,
        },
      ],
    }),
    stopProject: build.mutation<null, { projectId: ProjectId; orgId?: OrgId }>({
      query: ({ projectId, orgId }) => {
        // This has to be part of the URL for baseQueryWithOrgId(). params helper is added after baseQueryWithOrgId()
        const query = orgId ? `?orgId=${orgId}` : ''
        return {
          url: `${baseUri}projects/${projectId}/stop${query}`,
          method: 'POST',
        }
      },
      invalidatesTags: (result, _error, arg) => [
        {
          type: 'Project',
          id: arg.projectId,
        },
      ],
    }),
    getProjectLobby: build.query<
      GetProjectLobby,
      { workflowSessionId: WorkflowSessionId }
    >({
      query: ({ workflowSessionId }) => ({
        url: `${baseUri}sessions/${workflowSessionId}/lobby`,
        method: 'GET',
      }),
      keepUnusedDataFor: 5,
    }),
  }),
  overrideExisting: false,
})

export const {
  useGetProjectQuery,
  useUpdateProjectNameAndDescriptionMutation,
  useListProjectQuery,
  useUpdateProjectMutation,
  useUpdateProjectWorkflowMutation,
  useDeleteProjectMutation,
  useCreateProjectMutation,
  useStartProjectMutation,
  useStopProjectMutation,
  useGetProjectLobbyQuery,
} = projectApi

export const makeSelectAvailablePadsFromProjectWorkflow = () =>
  createSelector(
    (state: RootState, projectId: string, componentId: string) => {
      const selector = projectApi.endpoints.getProject.select(projectId)
      return { ...selector(state), componentId }
    },
    ({ data, componentId }) => {
      if (!data) return []
      const workflow = new Workflow(data.workflow)
      return workflow.getCandidateSourcePadsAvailableForConnection(componentId)
    }
  )

export const useSelectAvailablePadsFromProjectWorkflow = (
  projectId: string,
  componentId: string
) => {
  const selector = useMemo(makeSelectAvailablePadsFromProjectWorkflow, [])
  return useAppSelector((state: RootState) =>
    selector(state, projectId, componentId)
  )
}
