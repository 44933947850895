import {
  selectOrgId,
  setOrgId as reduxSetOrgId,
} from '@features/organisations/slice'
import { OrgId } from '@features/organisations/types'
import { useAppDispatch, useAppSelector } from '@lib/rtk/hooks'
import { useRouter } from 'next/router'
import { useEffect, useMemo, useCallback } from 'react'
import { mediaProjectsApi } from '@features/mediaProjectsApi/api'
import { ManageSelectedOrgContext } from './Context'

export const ManageSelectedOrgProvider = ({ children }) => {
  const {
    query: { orgId: queryOrgId },
  } = useRouter()
  const dispatch = useAppDispatch()
  const stateOrgId = useAppSelector(selectOrgId)

  const setOrgId = useCallback(
    (id: OrgId) => {
      if (id === stateOrgId) {
        // No need to set the orgId if it's the same as the one in the state
        return
      }
      dispatch(reduxSetOrgId(id))
      dispatch(mediaProjectsApi.util.resetApiState())
    },
    [dispatch, stateOrgId]
  )

  useEffect(() => {
    if (queryOrgId === undefined) {
      return
    }
    setOrgId(queryOrgId as string)
  }, [queryOrgId, setOrgId, stateOrgId])

  // Validate that the orgId is the same as the one in the state
  const orgId = stateOrgId === queryOrgId ? stateOrgId : null

  const memoizedValue = useMemo(() => ({ orgId, setOrgId }), [orgId, setOrgId])

  return (
    <ManageSelectedOrgContext.Provider value={memoizedValue}>
      {children}
    </ManageSelectedOrgContext.Provider>
  )
}
