import { Auth, getAuth as getClientAuth } from 'firebase/auth'
import { Tenancy } from '@lib/firebase/types'
import { getCookie } from '@lib/firebase/clientCookie'
import appClient from './client.app'
import { getTenanancyCookieName, getTenantId } from './config'

export const getClientTenantAuth = (): Auth => {
  const tenancy = getCookie(getTenanancyCookieName()) as Tenancy
  const auth = getClientAuth(appClient)
  auth.tenantId = getTenantId(tenancy)
  return auth
}

export default getClientTenantAuth
