import { Provider } from 'react-redux'
import { AppProps } from 'next/app'
import { Button, ChakraProvider } from '@chakra-ui/react'
import { PersistGate } from 'redux-persist/integration/react'
import useNProgressRoute from '@hooks/useNProgressRouter'
import * as Sentry from '@sentry/nextjs'
import { AuthProvider } from '@lib/firebase/provider'
import { ErrorFallback } from '@components/Fallback'
import { store, persistor } from '@lib/store'
import { ManageSelectedOrgProvider } from '@features/organisations/contexts/ManageSelectedOrg'
import { PermissionsCheckerProvider } from '@lib/permissions'
import { FallbackRender } from '@sentry/nextjs'
import theme from '../theme'
import '../components/styles/nprogress.css'
import 'react-datepicker/dist/react-datepicker.css'
import '@xyflow/react/dist/style.css'

const FailOver: FallbackRender = ({ resetError, ...rest }) => (
  <ErrorFallback
    {...rest}
    actionButton={<Button onClick={resetError}>Reset</Button>}
  />
)

const App = ({ Component, pageProps }: AppProps) => {
  useNProgressRoute()
  return (
    <AuthProvider>
      <Provider store={store}>
        <PersistGate persistor={persistor} loading={null}>
          <ManageSelectedOrgProvider>
            <PermissionsCheckerProvider>
              <ChakraProvider resetCSS theme={theme} portalZIndex={100}>
                <Sentry.ErrorBoundary fallback={FailOver}>
                  <Component {...pageProps} />
                </Sentry.ErrorBoundary>
              </ChakraProvider>
            </PermissionsCheckerProvider>
          </ManageSelectedOrgProvider>
        </PersistGate>
      </Provider>
    </AuthProvider>
  )
}

export default App
