import { z } from 'zod'

import {
  abstractComponent,
  COMPONENT_TYPES,
} from '@customTypes/cloudmix/sessions/components/base'
import { audioVideoPadSchema } from '../../pads'

export const VIDEO_PATTERNS = {
  smpte: 'smpte',
  none: 'none',
} as const

export const AUDIO_PATTERNS = {
  sine: 'sine',
  ticks: 'ticks',
  none: 'none',
} as const

export const testSourceComponentSchema = abstractComponent.extend({
  type: z
    .literal(COMPONENT_TYPES.testSource)
    .default(COMPONENT_TYPES.testSource),
  output: audioVideoPadSchema,
  videoPattern: z.nativeEnum(VIDEO_PATTERNS),
  audioPattern: z.nativeEnum(AUDIO_PATTERNS),
})
