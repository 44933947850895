import { Component, ComponentData } from '@features/projects/lib/base/Component'
import { Pad, PadData } from '@features/projects/lib/base/Pad'

/* eslint-disable react/sort-comp */
interface ImageSourceData extends ComponentData {
  location: string
  output: PadData
}

export class ImageSource extends Component {
  type = 'imageSource'

  output: Pad

  location: string

  constructor(data: ImageSourceData) {
    super(data)
    this.output = new Pad(data.output)
    this.location = data.location
  }

  toData() {
    const commonData = super.toData()
    return {
      ...commonData,
      type: this.getType(),
      output: this.output.toData(),
      location: this.location,
    }
  }

  static create() {
    const data = {
      output: new Pad(),
      location: '',
    }

    return new ImageSource(data)
  }

  patch(data: Partial<ImageSourceData>) {
    super.patch(data)
    const { output, ...rest } = data
    this.output.patch(output as Pad)
    Object.assign(this, { ...rest })
  }
}
