import { createSlice } from '@reduxjs/toolkit'
import { RootState } from '@lib/store'
import { OrgId } from './types'

interface SelectedOrgIdState {
  orgId: OrgId | null
}

const initialState: SelectedOrgIdState = {
  orgId: null,
}

const selectedOrgIdSlice = createSlice({
  name: 'selectedOrgId',
  initialState,
  reducers: {
    setOrgId: (state, action) => {
      state.orgId = action.payload
    },
  },
})

export const { setOrgId } = selectedOrgIdSlice.actions
export default selectedOrgIdSlice.reducer

export const selectOrgId = (state: RootState) => state.selectedOrgId.orgId
