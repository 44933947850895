import { createBreakpoints } from '@chakra-ui/theme-tools'

const breakpoints = createBreakpoints({
  sm: '40em',
  md: '52em',
  lg: '64em',
  xl: '80em',
  '2xl': '96em',
  '3xl': '120em',
  '4xl': '160em',
})

export default breakpoints
