import { extendTheme } from '@chakra-ui/react'
import foundations from './foundations'
import * as components from './components'

const direction = 'ltr'

const config = {
  useSystemColorMode: false,
  initialColorMode: 'dark',
  cssVarPrefix: 'chakra',
}

const theme = {
  direction,
  ...foundations,
  components: { ...components },
  config,
}

export default extendTheme(theme)
