import { Component, ComponentData } from '@features/projects/lib/base/Component'
import { Pad, PadData } from '@features/projects/lib/base/Pad'
/* eslint-disable react/sort-comp */
interface RtmpDestinationData extends ComponentData {
  address: string
  streamKey: string
  input: PadData
  enabled: boolean
}
export class RtmpDestination extends Component {
  type = 'rtmpDestination'

  address: string

  streamKey: string

  input: Pad

  enabled: boolean

  constructor(data: RtmpDestinationData) {
    super(data)
    this.address = data.address
    this.streamKey = data.streamKey
    this.input = new Pad(data.input)
    this.enabled = data.enabled
  }

  toData() {
    const commonData = super.toData()
    return {
      ...commonData,
      type: this.getType(),
      address: this.address,
      streamKey: this.streamKey,
      input: this.input.toData(),
      enabled: this.enabled,
    }
  }

  static fromData(data) {
    return new RtmpDestination(data)
  }

  static create() {
    const data = {
      address: 'rtmps://address/app',
      streamKey: 'streamKey',
      displayName: 'Rtmp Destination',
      input: new Pad(),
      enabled: false,
    }
    return new RtmpDestination(data)
  }

  patch(data: Partial<RtmpDestinationData>) {
    super.patch(data)
    const { input, ...rest } = data
    this.input.patch(input as Pad)
    Object.assign(this, { ...rest })
  }
}
