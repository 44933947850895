import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export type CameraVideoHeight = 360 | 720

type PinnedParticipant = {
  id: string
  type: string
}

interface CommentaryState {
  audio: string
  video: string
  videoHeight: CameraVideoHeight
  isAudioMuted: boolean
  isVideoMuted: boolean
  selectedParticipant: PinnedParticipant | null
}

export const initialState = {
  audioDeviceId: '',
  videoDeviceId: '',
  videoHeight: 720,
  constraints: {
    audio: true,
    video: { aspectRatio: 16 / 9, height: 720 },
  },
  isAudioMuted: false,
  isVideoMuted: false,
  selectedParticipant: null,
}

const commentarySlice = createSlice({
  name: 'commentary',
  initialState,
  reducers: {
    setVideoDeviceId: (state, action) => {
      state.videoDeviceId = action.payload
    },
    setAudioDeviceId: (state, action) => {
      state.audioDeviceId = action.payload
    },
    setVideoHeight: (state, action: PayloadAction<CameraVideoHeight>) => {
      state.videoHeight = action.payload
    },
    setIsAudioMuted: (state, action: PayloadAction<boolean>) => {
      state.isAudioMuted = action.payload
    },
    setIsVideoMuted: (state, action: PayloadAction<boolean>) => {
      state.isVideoMuted = action.payload
    },
  },
})

export const {
  setVideoDeviceId,
  setAudioDeviceId,
  setVideoHeight,
  setIsAudioMuted,
  setIsVideoMuted,
} = commentarySlice.actions
export default commentarySlice.reducer
