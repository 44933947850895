/* eslint-disable no-bitwise */
export enum Operations {
  None = 0,
  Delete = 1,
  Update = 2,
  Read = 4,
  Create = 8,
  Write = Operations.Create | Operations.Update | Operations.Delete,
  All = Operations.Read | Operations.Write,
}
/* eslint-enable no-bitwise */
