import { Mappable } from '@features/projects/lib/base/Mappable'
import { v4 as uuidv4 } from 'uuid'
import { getValueOrDefault } from '../../../../utils/utils'
/* eslint-disable react/sort-comp */
export interface PadData {
  displayName?: string
  name?: string
}
export class Pad implements Mappable<PadData> {
  displayName: string

  name: string

  constructor(data?: PadData) {
    const defaultId = uuidv4()
    this.displayName = getValueOrDefault(data?.displayName, defaultId)
    this.name = getValueOrDefault(data?.name, defaultId)
  }

  toData(): PadData {
    return {
      displayName: this.displayName,
      name: this.name,
    }
  }

  patch(data: Partial<PadData>) {
    if (data) {
      Object.keys(data).forEach((key) => {
        if (
          data[key as keyof PadData] !== undefined &&
          data[key as keyof PadData] !== null &&
          data[key as keyof PadData] !== ''
        ) {
          this[key as keyof PadData] = data[key as keyof PadData]!
        }
      })
    }
  }
}
