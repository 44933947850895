import { ConnectionStateEnum } from '@hooks/useWebRTCMediaServer/mediasoupOrchestrator/ConnectionState'
import {
  createEntityAdapter,
  createSelector,
  createSlice,
} from '@reduxjs/toolkit'
import { RootState, store } from '@lib/store'
import { WorkflowSessionId } from '@customTypes/cloudmix/workflow'

export interface OutgoingDataChannel {
  id: string
  peerId: string
  workflowSessionId: WorkflowSessionId
  connectionState: ConnectionStateEnum
}

const outgoingDataChannelAdapter = createEntityAdapter({
  selectId: (outgoingDataChannel: OutgoingDataChannel) =>
    outgoingDataChannel.id,
})

const outgoingDataChannelSlice = createSlice({
  name: 'outgoingDataChannel',
  initialState: outgoingDataChannelAdapter.getInitialState(),
  reducers: {
    addOutgoingDataChannel: outgoingDataChannelAdapter.addOne,
    removeOutgoingDataChannel: outgoingDataChannelAdapter.removeOne,
    addManyOutgoingDataChannels: outgoingDataChannelAdapter.addMany,
    updateOutgoingDataChannel: outgoingDataChannelAdapter.updateOne,
    updateOutgoingDataChannelConnectionState: (state, action) => {
      const { id, connectionState } = action.payload
      const outgoingDataChannel = state.entities[id]
      if (outgoingDataChannel) {
        outgoingDataChannel.connectionState = connectionState
      }
    },
  },
})

export default outgoingDataChannelSlice.reducer

export const {
  addOutgoingDataChannel,
  removeOutgoingDataChannel,
  addManyOutgoingDataChannels,
  updateOutgoingDataChannel,
  updateOutgoingDataChannelConnectionState,
} = outgoingDataChannelSlice.actions

const outgoingDataChannelSelectors =
  outgoingDataChannelAdapter.getSelectors<RootState>(
    (state) => state.projectWebRTCOutgoingData
  )

export const selectOutgoingDataChannels = outgoingDataChannelSelectors.selectAll

export const selectOutgoingDataChannelById = (state, outgoingDataChannelId) =>
  outgoingDataChannelSelectors.selectById(state, outgoingDataChannelId)

export const selectOutgoingDataChannelsByPeerId = (state, peerId) =>
  outgoingDataChannelSelectors
    .selectAll(state)
    .filter((outgoingDataChannel) => outgoingDataChannel.peerId === peerId)

export const selectOutgoingDataChannelsByWorkflowSessionId = createSelector(
  [selectOutgoingDataChannels, (state, workflowSessionId) => workflowSessionId],
  (channels, workflowSessionId) =>
    channels.filter(
      (outgoingDataChannel) =>
        outgoingDataChannel.workflowSessionId === workflowSessionId
    )
)
