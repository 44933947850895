import {
  BaseQueryFn,
  createApi,
  FetchArgs,
  fetchBaseQuery,
  FetchBaseQueryError,
} from '@reduxjs/toolkit/query/react'
import getClientTenantAuth from '@lib/firebase/getClientTenantAuth'
import { OrgId } from '@features/organisations/types'
import { RootState } from '@lib/store'
import { MediaLibrary, Directory } from '../types'
import { selectDirectory, selectMediaItem } from './selectors'
import {
  CreateDirectoryRequest,
  CreateMediaItemResponse,
  CreateMediaItemRequest,
  ConfirmEtagsRequest,
  GetMediaItemsRequest,
  DeleteMediaLibraryRequest,
  RenameLibraryItemRequest,
  MoveLibraryItemRequest,
  GenerateUploadUrlsRequest,
  GenerateUploadUrlsResponse,
} from './types'
import {
  buildGetMediaItemsPayload,
  getRootDirectoryId,
  recursivelyAddParentDirectoryId,
  recursivelyUpdateGetMediaItemsCache,
} from './utils'
import { UPLOAD_PART_SIZE } from '../utils/fileUpload'

const rawBaseQuery = fetchBaseQuery({
  baseUrl: process.env.NEXT_PUBLIC_MEDIA_LIBRARY_API_URI,
  prepareHeaders: async (headers) => {
    const auth = getClientTenantAuth()
    if (auth) {
      await auth?.authStateReady()
      const token = await auth?.currentUser?.getIdToken()
      if (token) headers.set('Authorization', `GipIdToken ${token}`)
    }

    return headers
  },
})

const dynamicBaseQuery: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  const { selectedOrgId } = api.getState() as {
    selectedOrgId: { orgId: OrgId }
  }
  const urlEnd = typeof args === 'string' ? args : args.url

  let adjustedUrl = urlEnd
  if (selectedOrgId?.orgId)
    adjustedUrl = `${urlEnd}?orgId=${selectedOrgId.orgId}`

  const adjustedArgs =
    typeof args === 'string' ? adjustedUrl : { ...args, url: adjustedUrl }
  return rawBaseQuery(adjustedArgs, api, extraOptions)
}

export const mediaLibraryApi = createApi({
  reducerPath: 'mediaLibraryApi',
  baseQuery: dynamicBaseQuery,
  tagTypes: ['Library'],
  endpoints: (builder) => ({
    createDirectory: builder.mutation<null, CreateDirectoryRequest>({
      query: (directory) => ({
        url: 'directories',
        method: 'POST',
        body: directory,
      }),
      invalidatesTags: (result, error, arg) => [
        { type: 'Library', id: arg.parentDirectoryId },
      ],
    }),
    renameDirectory: builder.mutation<null, RenameLibraryItemRequest>({
      query: ({ id, name }) => ({
        url: `directories/${id}/location`,
        method: 'PATCH',
        body: { name },
      }),
      async onQueryStarted(
        { id, name, parentDirectoryId },
        { dispatch, queryFulfilled }
      ) {
        const patchResult = dispatch(
          mediaLibraryApi.util.updateQueryData(
            'getMediaItems',
            buildGetMediaItemsPayload(parentDirectoryId),
            (draft) => {
              const directory = draft.directories.find((dir) => dir.id === id)
              if (directory) directory.name = name
            }
          )
        )

        try {
          await queryFulfilled
        } catch (error) {
          patchResult.undo()
        }
      },
    }),
    moveDirectory: builder.mutation<null, MoveLibraryItemRequest>({
      query: ({ id, newParentDirectoryId }) => ({
        url: `directories/${id}/location`,
        method: 'PATCH',
        body: { parentDirectoryId: newParentDirectoryId },
      }),
      async onQueryStarted(
        { id, newParentDirectoryId, currentParentDirectoryId },
        { dispatch, queryFulfilled, getState }
      ) {
        const readOnlyDirectoryToMove = selectDirectory(
          // TODO: getState() thinks it's returning mediaLibraryApi state, but it's actually returning the whole store
          getState() as RootState,
          id,
          currentParentDirectoryId,
          mediaLibraryApi
        )

        if (!readOnlyDirectoryToMove) return
        const directoryToMove = { ...readOnlyDirectoryToMove }

        directoryToMove.parentDirectoryId = newParentDirectoryId

        const removeDirectory = dispatch(
          mediaLibraryApi.util.updateQueryData(
            'getMediaItems',
            buildGetMediaItemsPayload(currentParentDirectoryId),
            (draft) => {
              // eslint-disable-next-line no-param-reassign
              draft.directories = draft.directories.filter(
                (dir) => dir.id !== id
              )
            }
          )
        )

        const addDirectory = dispatch(
          mediaLibraryApi.util.updateQueryData(
            'getMediaItems',
            buildGetMediaItemsPayload(newParentDirectoryId),
            (draft) => {
              draft.directories.push(directoryToMove)
            }
          )
        )

        try {
          await queryFulfilled
        } catch (error) {
          removeDirectory.undo()
          addDirectory.undo()
        }
      },
    }),
    deleteDirectory: builder.mutation<null, DeleteMediaLibraryRequest>({
      query: ({ id }) => ({
        url: `directories/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, arg) => [
        { type: 'Library', id: arg.id },
        { type: 'Library', id: arg.parentDirectoryId },
      ],
    }),
    createMediaItem: builder.mutation<
      CreateMediaItemResponse,
      CreateMediaItemRequest
    >({
      query: (mediaItem) => ({
        url: 'mediaItems',
        method: 'POST',
        body: { ...mediaItem, uploadPartSize: UPLOAD_PART_SIZE },
      }),
      invalidatesTags: (result, error, arg) => [
        { type: 'Library', id: arg.parentDirectoryId },
      ],
    }),
    generateUploadUrls: builder.mutation<
      GenerateUploadUrlsResponse,
      GenerateUploadUrlsRequest
    >({
      query: ({ id, uploadSize }) => ({
        url: `mediaItems/${id}/upload/urls`,
        method: 'POST',
        body: { uploadSize, uploadPartSize: UPLOAD_PART_SIZE },
      }),
    }),
    confirmEtags: builder.mutation<null, ConfirmEtagsRequest>({
      query: ({ id, eTags }) => ({
        url: `mediaItems/${id}/upload/eTags`,
        method: 'POST',
        body: { eTags },
      }),
      invalidatesTags: (result, error, arg) => [
        { type: 'Library', id: arg.parentDirectoryId },
      ],
    }),
    getMediaItems: builder.query<MediaLibrary, GetMediaItemsRequest>({
      query: ({ id, maxDepth = 2 }) => ({
        url: `mediaItems`,
        method: 'GET',
        params: { rootDirectoryId: id, maxDepth },
      }),
      transformResponse(baseQueryReturnValue: MediaLibrary, meta, { id }) {
        const directory = {
          ...baseQueryReturnValue,
          // Root directory is the default on the api if no id is provided
          id: id ?? getRootDirectoryId(),
        } as Directory
        return recursivelyAddParentDirectoryId(directory)
      },
      providesTags: (result, error, { id }) =>
        id
          ? [{ type: 'Library', id }]
          : [{ type: 'Library', id: getRootDirectoryId() }],
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled

          data.directories.forEach((directory) => {
            recursivelyUpdateGetMediaItemsCache(
              directory,
              mediaLibraryApi,
              dispatch
            )
          })
        } catch {
          console.error('Error fetching media items')
        }
      },
    }),
    renameMediaItem: builder.mutation<null, RenameLibraryItemRequest>({
      query: ({ id, name }) => ({
        url: `mediaItems/${id}/location`,
        method: 'PATCH',
        body: { name },
      }),
      async onQueryStarted(
        { id, name, parentDirectoryId },
        { dispatch, queryFulfilled }
      ) {
        const patchResult = dispatch(
          mediaLibraryApi.util.updateQueryData(
            'getMediaItems',
            buildGetMediaItemsPayload(parentDirectoryId),
            (draft) => {
              const mediaItem = draft.mediaItems.find((item) => item.id === id)
              if (mediaItem) mediaItem.name = name
            }
          )
        )

        try {
          await queryFulfilled
        } catch (error) {
          patchResult.undo()
        }
      },
    }),
    moveMediaItem: builder.mutation<null, MoveLibraryItemRequest>({
      query: ({ id, newParentDirectoryId }) => ({
        url: `mediaItems/${id}/location`,
        method: 'PATCH',
        body: { parentDirectoryId: newParentDirectoryId },
      }),
      async onQueryStarted(
        { id, newParentDirectoryId, currentParentDirectoryId },
        { dispatch, queryFulfilled, getState }
      ) {
        const readOnlyMediaItemToMove = selectMediaItem(
          getState() as RootState,
          id,
          currentParentDirectoryId,
          mediaLibraryApi
        )

        if (!readOnlyMediaItemToMove) return
        const mediaItemToMove = { ...readOnlyMediaItemToMove }
        mediaItemToMove.parentDirectoryId = newParentDirectoryId

        const removeMediaItem = dispatch(
          mediaLibraryApi.util.updateQueryData(
            'getMediaItems',
            buildGetMediaItemsPayload(currentParentDirectoryId),
            (draft) => {
              // eslint-disable-next-line no-param-reassign
              draft.mediaItems = draft.mediaItems.filter((dir) => dir.id !== id)
            }
          )
        )

        const addMediaItem = dispatch(
          mediaLibraryApi.util.updateQueryData(
            'getMediaItems',
            buildGetMediaItemsPayload(newParentDirectoryId),
            (draft) => {
              draft.mediaItems.push(mediaItemToMove)
            }
          )
        )

        try {
          await queryFulfilled
        } catch (error) {
          removeMediaItem.undo()
          addMediaItem.undo()
        }
      },
    }),
    deleteMediaItem: builder.mutation<null, DeleteMediaLibraryRequest>({
      query: (request) => ({
        url: `mediaItems/${request.id}`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, arg) => [
        { type: 'Library', id: arg.parentDirectoryId },
      ],
    }),
  }),
})

export const {
  useCreateDirectoryMutation,
  useRenameDirectoryMutation,
  useMoveDirectoryMutation,
  useDeleteDirectoryMutation,
  useCreateMediaItemMutation,
  useGenerateUploadUrlsMutation,
  useConfirmEtagsMutation,
  useGetMediaItemsQuery,
  useRenameMediaItemMutation,
  useMoveMediaItemMutation,
  useDeleteMediaItemMutation,
} = mediaLibraryApi
