import { Component, ComponentData } from '@features/projects/lib/base/Component'
import { Pad, PadData } from '@features/projects/lib/base/Pad'
import {
  AUDIO_PATTERNS,
  VIDEO_PATTERNS,
} from '@customTypes/cloudmix/sessions/components/testSource'

/* eslint-disable react/sort-comp */
interface TestSourceData extends ComponentData {
  audioPattern: typeof AUDIO_PATTERNS[keyof typeof AUDIO_PATTERNS]
  videoPattern: typeof VIDEO_PATTERNS[keyof typeof VIDEO_PATTERNS]
  output: PadData
}

export class TestSource extends Component {
  type = 'testSource'

  audioPattern: typeof AUDIO_PATTERNS[keyof typeof AUDIO_PATTERNS]

  videoPattern: typeof VIDEO_PATTERNS[keyof typeof VIDEO_PATTERNS]

  output: Pad

  constructor(data: TestSourceData) {
    super(data)
    this.audioPattern = data.audioPattern
    this.videoPattern = data.videoPattern
    this.output = new Pad(data.output)
  }

  toData() {
    const commonData = super.toData()
    return {
      ...commonData,
      type: this.getType(),
      audioPattern: this.audioPattern,
      videoPattern: this.videoPattern,
      output: this.output.toData(),
    }
  }

  static create() {
    const data = {
      videoPattern: VIDEO_PATTERNS.smpte,
      audioPattern: AUDIO_PATTERNS.sine,
      output: new Pad(),
    }
    return new TestSource(data)
  }

  patch(data: Partial<TestSourceData>) {
    super.patch(data)
    const { output, ...rest } = data
    this.output.patch(output as Pad)
    Object.assign(this, { ...rest })
  }
}
