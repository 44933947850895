import { Component, ComponentData } from '@features/projects/lib/base/Component'
import { Pad, PadData } from '@features/projects/lib/base/Pad'

/* eslint-disable react/sort-comp */
interface LiveOutputDestinationData extends ComponentData {
  liveOutputId: string
  input: PadData
  enabled: boolean
}
export class LiveOutputDestination extends Component {
  type = 'liveOutputDestination'

  liveOutputId: string

  input: Pad

  enabled: boolean

  constructor(data: LiveOutputDestinationData) {
    super(data)
    this.liveOutputId = data.liveOutputId
    this.input = new Pad(data.input)
    this.enabled = data.enabled
  }

  toData() {
    const commonData = super.toData()
    return {
      ...commonData,
      type: this.getType(),
      input: this.input.toData(),
      liveOutputId: this.liveOutputId,
      enabled: this.enabled,
    }
  }

  static create(liveOutputId = '', displayName?: string) {
    const data = {
      liveOutputId,
      displayName,
      input: new Pad(),
      enabled: false,
    }
    return new LiveOutputDestination(data)
  }

  patch(data: Partial<LiveOutputDestinationData>) {
    super.patch(data)
    const { input, ...rest } = data
    this.input.patch(input as Pad)
    Object.assign(this, { ...rest })
  }
}
