import {
  BaseQueryFn,
  createApi,
  FetchArgs,
  fetchBaseQuery,
  FetchBaseQueryError,
} from '@reduxjs/toolkit/query/react'
import getClientTenantAuth from '@lib/firebase/getClientTenantAuth'
import { OrgId } from '../../organisations/types'
import { LegacyApiResponse } from '../types'

const rawBaseQuery = fetchBaseQuery({
  baseUrl: `${process.env.NEXT_PUBLIC_CMX_API_URI}/`,
  prepareHeaders: async (headers) => {
    // Get token from cookie of firebase user?
    const auth = getClientTenantAuth()
    if (auth) {
      await auth?.authStateReady()
      const token = await auth?.currentUser?.getIdToken()
      if (token) {
        headers.set('Authorization', `GipIdToken ${token}`)
      }
    }

    return headers
  },
})

const dynamicBaseQuery: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  const { selectedOrg } = api.getState() as { selectedOrg }
  const orgId: OrgId | null = selectedOrg?.id || null
  const urlEnd = typeof args === 'string' ? args : args.url

  let adjustedUrl = urlEnd
  // construct a dynamically generated portion of the url
  if (orgId !== null && urlEnd.search(/orgId=/) === -1) {
    adjustedUrl =
      urlEnd.search(/\/([a-z]*[A-Z]*[0-9]*)+\?/g) === -1
        ? `${urlEnd}?orgId=${orgId}`
        : `${urlEnd}&orgId=${orgId}`
  }

  // provide the amended url and other params to the raw base query
  const adjustedArgs =
    typeof args === 'string' ? adjustedUrl : { ...args, url: adjustedUrl }
  return rawBaseQuery(adjustedArgs, api, extraOptions)
}

export const cloudmixApi = createApi({
  reducerPath: 'cloudmixApi',
  tagTypes: [
    'Commentary',
    'Commentators',
    'EventAlert',
    'Facebook',
    'UserID',
    'YouTube',
  ],
  baseQuery: dynamicBaseQuery,
  endpoints: (_build) => ({}),
})
