import { createSlice, PayloadAction } from '@reduxjs/toolkit'

type WebSocketStatus =
  | 'connected'
  | 'disconnected'
  | 'connecting'
  | 'authenticating'
  | 'authenticated'
interface ProjectSocketState {
  [projectId: string]: { status: WebSocketStatus }
}

const initialState: ProjectSocketState = {}

const projectSocketSlice = createSlice({
  name: 'projectSocket',
  initialState,
  reducers: {
    setConnected: (state, action: PayloadAction<{ projectId: string }>) => {
      const { projectId } = action.payload
      state[projectId] = { status: 'connected' }
    },
    setAuthenticated: (state, action: PayloadAction<{ projectId: string }>) => {
      const { projectId } = action.payload
      state[projectId] = { status: 'authenticated' }
    },
    setAuthenticating: (
      state,
      action: PayloadAction<{ projectId: string }>
    ) => {
      const { projectId } = action.payload
      state[projectId] = { status: 'authenticating' }
    },
    setDisconnected: (state, action: PayloadAction<{ projectId: string }>) => {
      const { projectId } = action.payload
      state[projectId] = { status: 'disconnected' }
    },
    setConnecting: (state, action: PayloadAction<{ projectId: string }>) => {
      const { projectId } = action.payload
      state[projectId] = { status: 'connecting' }
    },
    removeStatus: (state, action: PayloadAction<{ projectId: string }>) => {
      const { projectId } = action.payload
      delete state[projectId]
    },
  },
})

export const {
  setConnected,
  setAuthenticating,
  setAuthenticated,
  setConnecting,
  setDisconnected,
  removeStatus,
} = projectSocketSlice.actions

export const selectSocketStatusByProjectId = (
  state: { projectSocket: ProjectSocketState },
  projectId: string
) => state.projectSocket[projectId]?.status

export default projectSocketSlice.reducer
