import { Operations } from './Operations'

/* eslint-disable no-underscore-dangle */
export class Permission {
  constructor(
    private readonly _resourceId: string[],
    private readonly _operations: Operations
  ) {}

  get resourceId(): string[] {
    return this._resourceId
  }

  get operations(): Operations {
    return this._operations
  }
}
/* eslint-enable no-underscore-dangle */
