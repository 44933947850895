import {
  AuthenticationResponse,
  OrgId,
  PermissionRule as PermissionRuleType,
} from '@features/organisations/types'
import { PermissionRule } from './PermissionRule'
import { Permission } from './Permission'
import { PermissionsUtil } from './PermissionsUtil'

const fetchAuthentication = async (
  token: string,
  orgId: string
): Promise<AuthenticationResponse> => {
  const response = await fetch(
    `${process.env.NEXT_PUBLIC_USER_MANAGEMENT_API_URI}/api/authenticate?orgId=${orgId}`,
    {
      method: 'GET',
      headers: {
        Authorization: `GipIdToken ${token}`,
      },
    }
  )
  return response.json()
}

export const checkAuthenticationSSR = async (
  token: string,
  orgId: OrgId,
  permissions: Permission[]
): Promise<boolean> => {
  try {
    const data = await fetchAuthentication(token, orgId)
    if (!data) {
      return false
    }

    const isStaff = data?.isStaff
    const hasOrgAccess = data?.hasOrgAccess

    let permissionRulesJson: PermissionRuleType[] = []
    if (hasOrgAccess) {
      permissionRulesJson = data?.orgAccessDetails.permissionRules
    }
    if (isStaff) {
      permissionRulesJson = data?.staffPermissionRules
    }

    const permissionRules = permissionRulesJson.map(
      (rule) =>
        new PermissionRule(rule.type, rule.resourcePattern, rule.operations)
    )

    return permissions.every((permission) =>
      PermissionsUtil.permissionIsGranted(permission, permissionRules)
    )
  } catch (err) {
    return false
  }
}
