import {
  mode,
  StyleFunctionProps,
  transparentize,
} from '@chakra-ui/theme-tools'

const baseStyle = (props: StyleFunctionProps) => ({
  field: {
    _readOnly: {
      color: mode('gray.600', 'gray.300')(props),
      bg: mode('whiteAlpha.100', 'gray.800')(props),
      borderColor: mode('gray.200', 'whiteAlpha.50')(props),
    },
  },
  element: {
    overflowY: 'clip',
    w: 'fit-content',
    minW: '1.7rem',
  },
})

const variants = {
  outline: (props: StyleFunctionProps) => ({
    field: {
      borderRadius: 'sm',
      bg: mode('white', 'gray.800')(props),
      _hover: { borderColor: mode('gray.300', 'gray.600')(props) },
      _focus: {
        borderColor: mode('brand.500', 'brand.200')(props),
        boxShadow: mode(
          `0px 0px 0px 1px ${transparentize(`brand.500`, 1.0)(props.theme)}`,
          `0px 0px 0px 1px ${transparentize(`brand.200`, 1.0)(props.theme)}`
        )(props),
      },
    },
    addon: {
      borderRadius: 'sm',
      bg: mode('gray.50', 'gray.700')(props),
    },
  }),
  'outline-on-accent': (props: StyleFunctionProps) => ({
    field: {
      bg: 'white',
      borderRadius: 'sm',
      color: 'gray.900',
      borderWidth: '1px',
      borderColor: 'brand.50',
      _placeholder: {
        color: 'gray.500',
      },
      _hover: {
        borderColor: 'brand.100',
      },
      _focus: {
        borderColor: 'brand.200',
        boxShadow: `0px 0px 0px 1px ${transparentize(
          `brand.200`,
          1.0
        )(props.theme)}`,
      },
    },
  }),
  filled: (props: StyleFunctionProps) => {
    if (props.colorScheme === 'gray') {
      return {
        field: {
          bg: mode('white', 'gray.800')(props),
          _hover: {
            borderColor: mode('gray.200', 'gray.700')(props),
            bg: mode('white', 'gray.700')(props),
          },
          _focus: {
            borderColor: 'accent',
            bg: mode('white', 'gray.800')(props),
          },
        },
      }
    }
    return {
      field: {
        bg: 'bg-accent-subtle',
        color: 'on-accent',
        _placeholder: {
          color: 'on-accent',
        },
        _hover: {
          borderColor: 'brand.400',
          bg: 'bg-accent-subtle',
        },
        _focus: {
          bg: 'bg-accent-subtle',
          borderColor: 'brand.300',
        },
      },
    }
  },
}

const commonSizes = {
  borderRadius: 'xs',
  paddingInlineStart: '0.4rem',
  paddingInlineEnd: '0.4rem',
  marginBottom: '0.2rem',
}

const xsSizes = {
  fontSize: '0.8rem',
  height: '0.7rem',
  py: '0.7rem',
  marginTop: '0',
  ...commonSizes,
}

const smSizes = {
  fontSize: '0.9rem',
  height: '1.7rem',
  py: '0.5rem',
  ...commonSizes,
}

const lgSizes = {
  fontSize: 'md',
  borderRadius: 'sm',
}

const sizes = {
  xs: {
    field: xsSizes,
    addon: xsSizes,
  },
  sm: {
    field: smSizes,
    addon: smSizes,
  },
  lg: {
    field: lgSizes,
    addon: lgSizes,
  },
}

export default {
  baseStyle,
  variants,
  sizes,
  defaultProps: {
    colorScheme: 'gray',
    size: 'sm',
  },
}
