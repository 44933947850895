import { createEntityAdapter, createSlice, EntityState } from '@reduxjs/toolkit'
import { Peer } from '@features/projects/slices/projectWebRTCSession/peer/slice'
import { Stream } from '@features/projects/slices/projectWebRTCSession/stream/slice'
import { RootState } from '@lib/store'
import { ConnectionStateEnum } from '@hooks/useWebRTCMediaServer/mediasoupOrchestrator/ConnectionState'
import { WorkflowSessionId } from '@customTypes/cloudmix/workflow'

interface Project {
  id: string
  peers: string[]
  streams: string[]
  connectionState: ConnectionStateEnum
  incomingDataChannels: string[]
  outgoingDataChannels: string[]
}

const projectAdapter = createEntityAdapter<Project>()

const projectWebRTCSessionSlice = createSlice({
  name: 'projectWebRTCSession',
  initialState: projectAdapter.getInitialState(),
  reducers: {
    addProject: projectAdapter.addOne,
    removeProject: projectAdapter.removeOne,
    updateProject: projectAdapter.updateOne,
    updateProjectConnectionState: (state, action) => {
      const { workflowSessionId, connectionState } = action.payload
      const project = state.entities[workflowSessionId]
      if (project) {
        project.connectionState = connectionState
      }
    },
    addPeerToProject: (state, action) => {
      const { workflowSessionId, peerId } = action.payload
      const project = state.entities[workflowSessionId]
      if (project) {
        project.peers.push(peerId)
      }
    },
    removePeerFromProject: (state, action) => {
      const { workflowSessionId, peerId } = action.payload
      const project = state.entities[workflowSessionId]
      if (project) {
        project.peers = project.peers.filter((id) => id !== peerId)
      }
    },
    addIncomingDataChannelToProject: (state, action) => {
      const { workflowSessionId, incomingDataChannelId } = action.payload
      const project = state.entities[workflowSessionId]
      if (project) {
        project.incomingDataChannels.push(incomingDataChannelId)
      }
    },
    removeIncomingDataChannelFromProject: (state, action) => {
      const { workflowSessionId, incomingDataChannelId } = action.payload
      const project = state.entities[workflowSessionId]
      if (project) {
        project.incomingDataChannels = project.incomingDataChannels.filter(
          (id) => id !== incomingDataChannelId
        )
      }
    },
    addOutgoingDataChannelToProject: (state, action) => {
      const { workflowSessionId, outgoingDataChannelId } = action.payload
      const project = state.entities[workflowSessionId]
      if (project) {
        project.outgoingDataChannels.push(outgoingDataChannelId)
      }
    },
    removeOutgoingDataChannelFromProject: (state, action) => {
      const { workflowSessionId, outgoingDataChannelId } = action.payload
      const project = state.entities[workflowSessionId]
      if (project) {
        project.outgoingDataChannels = project.outgoingDataChannels.filter(
          (id) => id !== outgoingDataChannelId
        )
      }
    },
    addStreamToProject: (state, action) => {
      const { workflowSessionId, streamId } = action.payload
      const project = state.entities[workflowSessionId]
      if (project) {
        if (!project.streams.includes(streamId)) {
          project.streams.push(streamId)
        }
      }
    },
    removeStreamFromProject: (state, action) => {
      const { workflowSessionId, streamId } = action.payload
      const project = state.entities[workflowSessionId]
      if (project) {
        project.streams = project.streams.filter((id) => id !== streamId)
      }
    },
  },
})

export default projectWebRTCSessionSlice.reducer

export const {
  addProject,
  removeProject,
  updateProject,
  updateProjectConnectionState,
  addPeerToProject,
  removePeerFromProject,
  addIncomingDataChannelToProject,
  removeIncomingDataChannelFromProject,
  addOutgoingDataChannelToProject,
  removeOutgoingDataChannelFromProject,
  addStreamToProject,
  removeStreamFromProject,
} = projectWebRTCSessionSlice.actions

const projectSelectors = projectAdapter.getSelectors<RootState>(
  (state) => state.projectWebRTCSession
)

export const selectProjects = projectSelectors.selectAll

export const selectProjectById = (
  state: RootState,
  workflowSessionId: WorkflowSessionId
) => projectSelectors.selectById(state, workflowSessionId)

export const selectProjectByPeerId = (state: RootState, peerId: string) =>
  projectSelectors
    .selectAll(state)
    .filter((project: Project) => project.peers.includes(peerId))

export const selectProjectStreams = (
  state: RootState,
  workflowSessionId: WorkflowSessionId
) =>
  projectSelectors
    .selectAll(state)
    .find((project: Project) => project.id === workflowSessionId)?.streams

export const selectProjectConnectionState = (
  state: RootState,
  workflowSessionId: WorkflowSessionId
) =>
  projectSelectors
    .selectAll(state)
    .find((project: Project) => project.id === workflowSessionId)
    ?.connectionState
