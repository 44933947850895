// import borders from './borders'
import breakpoints from './breakpoints'
import colors from './colors'
import styles from './styles'
import semanticTokens from './tokens'
// import radii from './radius'
import shadows from './shadows'
// import sizes from './sizes'
// import spacing from './spacing'
// import transition from './transition'
// import typography from './typography'
// import zIndices from './z-index'
// import blur from './blur'

const foundations = {
  breakpoints,
  styles,
  semanticTokens,
  // zIndices,
  // radii,
  // blur,
  colors: { ...colors, brand: colors.blue },
  // ...typography,
  // sizes,
  shadows,
  // space: spacing,
  // borders,
  // transition,
}

export default foundations
