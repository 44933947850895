import { Mappable } from '@features/projects/lib/base/Mappable'
import { Pad, PadData } from '@features/projects/lib/base/Pad'
import { v4 as uuidv4 } from 'uuid'
import { MixerPad } from '@features/projects/lib/base/MixerPad'
/* eslint-disable react/sort-comp */
export interface ComponentData {
  componentId?: string
  type?: string
  displayName?: string
  input?: PadData
  output?: PadData
  inputs?: PadData[] | MixerPad[]
  outputs?: PadData[]
}
export class Component implements Mappable<unknown> {
  componentId?: string

  type?: string

  displayName?: string

  input?: Pad

  output?: Pad

  inputs?: Pad[]

  outputs?: Pad[]

  constructor(data: ComponentData) {
    this.componentId = data?.componentId ?? uuidv4()
    this.type = data?.type
    this.displayName = data.displayName
    if (data.input) {
      this.input = new Pad(data.input)
    }

    if (data.inputs) {
      this.inputs = data.inputs.map((d) => new Pad(d))
    }

    if (data.output) {
      this.output = new Pad(data.output)
    }

    if (data.outputs) {
      this.outputs = data.outputs.map((d) => new Pad(d))
    }
  }

  getType() {
    return this.type
  }

  toData() {
    const data: any = {
      componentId: this.componentId,
      type: this.type,
      displayName: this.displayName,
    }

    if (this.input) {
      data.input = this.input.toData()
    }

    if (this.inputs) {
      data.inputs = this.inputs.map((input) => input.toData())
    }

    if (this.output) {
      data.output = this.output.toData()
    }

    if (this.outputs) {
      data.outputs = this.outputs.map((output) => output.toData())
    }

    return data
  }

  producesOutput() {
    return !!this.output || !!this.outputs
  }

  acceptsInput() {
    return !!this.input || !!this.inputs
  }

  getPadByName(name: string) {
    if (this.input && this.input.name === name) {
      return this.input
    }

    if (this.output && this.output.name === name) {
      return this.output
    }

    if (this.inputs) {
      const input = this.inputs.find((i) => i.name === name)
      if (input) {
        return input
      }
    }

    if (this.outputs) {
      const output = this.outputs.find((o) => o.name === name)
      if (output) {
        return output
      }
    }

    return null
  }

  patch(data: Partial<ComponentData>) {
    Object.keys(data).forEach((key) => {
      if (key in this) {
        if (key === 'input' && Object.hasOwn(data, key)) {
          const pad = data[key] as Pad
          this?.input?.patch(pad)
        } else if (key === 'output' && Object.hasOwn(data, key)) {
          const pad = data[key] as Pad
          this?.output?.patch(pad)
        } else if (key === 'inputs' && Object.hasOwn(data, key)) {
          this?.inputs?.forEach((input, index) => {
            const pad = data?.[key]?.[index] as Pad | MixerPad
            input.patch(pad)
          })
        } else if (key === 'outputs' && Object.hasOwn(data, key)) {
          this?.outputs?.forEach((output, index) => {
            const pad = data?.[key]?.[index] as Pad
            output.patch(pad)
          })
        } else {
          this[key] = data[key]
        }
      }
    })
  }
}
