import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ApiKey } from '../types'

interface ApiKeysState {
  newApiKey: ApiKey | null
}

const initialState: ApiKeysState = {
  newApiKey: null,
}

const apiKeysSlice = createSlice({
  name: 'apiKeys',
  initialState,
  reducers: {
    setNewApiKey(state, action: PayloadAction<ApiKey | null>) {
      state.newApiKey = action.payload
    },
    deleteNewApiKey(state) {
      state.newApiKey = null
    },
  },
})

export const { setNewApiKey, deleteNewApiKey } = apiKeysSlice.actions

export default apiKeysSlice.reducer
