import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Plan, PlanDuration } from './types'

export interface CheckoutState {
  selectedPlan: null | Plan
  selectedDuration: PlanDuration
}

export const initialState = {
  selectedPlan: null,
  selectedDuration: 'monthly',
} as CheckoutState

const checkoutSlice = createSlice({
  name: 'checkout',
  initialState,
  reducers: {
    selectPlan: (state, action: PayloadAction<Plan | null>) => {
      state.selectedPlan = action.payload
    },
    setPlanDuration: (state, action: PayloadAction<PlanDuration>) => {
      state.selectedDuration = action.payload
    },
  },
})
export const { selectPlan, setPlanDuration } = checkoutSlice.actions
export const isYearly = createSelector(
  (state) => state.checkout.selectedDuration,
  (val) => val === 'yearly'
)

export default checkoutSlice.reducer
