import { Skeleton } from '@chakra-ui/react'
import { FC, PropsWithChildren, ReactElement } from 'react'
import { usePermissionsCheckerContext, Permission } from '@lib/permissions'

interface CheckPermissionsWrapperProps {
  permissions: Permission[]
  failureComponent?: ReactElement
}

const CheckPermissionsWrapper: FC<
  PropsWithChildren<CheckPermissionsWrapperProps>
> = ({ permissions, failureComponent, children }) => {
  const { isLoading, hasPermissions, isError } = usePermissionsCheckerContext()

  if (isError) return failureComponent ?? null

  const isPermitted = hasPermissions(permissions)

  if (isPermitted || isLoading)
    return (
      <Skeleton isLoaded={!isLoading} fitContent>
        {children}
      </Skeleton>
    )

  return failureComponent ?? null
}

export default CheckPermissionsWrapper
