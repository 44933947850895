import { Tenancy, TENANT } from '@lib/firebase/types'
import {
  SecretsManagerClient,
  GetSecretValueCommand,
} from '@aws-sdk/client-secrets-manager'

const TWELVE_DAYS_IN_MS = 12 * 60 * 60 * 24 * 1000

export const core = {
  logoutUrl: '/api/logout',
  loginUrl: '/api/login',
  isDebug: process.env.FIREBASE_DEBUG_AUTH === 'true',
  authPageUrl: '/signin',
  appPageUrl: '/',
  staffAuthPageUrl: '/staff/signin',
  staffAppPageUrl: '/staff/o',
}
export const cookieConfig = {
  name: 'cloudmixApp', // required
  // Keys are required unless you set `signed` to `false`.
  // The keys cannot be accessible on the client side.
  keys: [process.env.COOKIE_SECRET_CURRENT, process.env.COOKIE_SECRET_PREVIOUS],
  httpOnly: true,
  maxAge: TWELVE_DAYS_IN_MS,
  overwrite: true,
  path: '/',
  sameSite: 'lax',
  secure: process.env.NEXT_PUBLIC_COOKIE_SECURE === 'true',
  signed: true,
}
export const getTenanancyCookieName = () => 'tenancy'

export const firebaseClientInitConfig = {
  apiKey: process.env.NEXT_PUBLIC_FIREBASE_PUBLIC_API_KEY,
  authDomain: process.env.NEXT_PUBLIC_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.NEXT_PUBLIC_FIREBASE_DATABASE_URL,
  projectId: process.env.NEXT_PUBLIC_FIREBASE_PROJECT_ID,
}

export const getFirebaseAdminInitConfig = async () => {
  // The private key must not be accessible on the client side.
  let privateKey: string
  if (process.env.FIREBASE_PRIVATE_KEY) {
    privateKey = JSON.parse(process.env.FIREBASE_PRIVATE_KEY)
  } else {
    const client = new SecretsManagerClient()
    const response = await client.send(
      new GetSecretValueCommand({
        SecretId: 'firebase-admin-sdk',
      })
    )
    const secretJson = JSON.parse(response.SecretString as string)
    privateKey = secretJson.private_key
  }

  const clientEmail = process.env.FIREBASE_CLIENT_EMAIL
  const projectId = process.env.NEXT_PUBLIC_FIREBASE_PROJECT_ID

  if (!clientEmail || !projectId || !privateKey) {
    throw new Error('Missing Firebase configuration values.')
  }

  return {
    credential: {
      clientEmail,
      projectId,
      privateKey,
    },
    databaseURL: 'https://optimal-primer-320705.firebaseio.com',
  }
}

export const getTenantId = (tenant?: Tenancy) => {
  let tenantId: string | undefined
  switch (tenant) {
    case TENANT.staff:
      tenantId = process.env.NEXT_PUBLIC_FIREBASE_STAFF_TENANT_ID
      break
    case TENANT.user:
    default:
      tenantId = process.env.NEXT_PUBLIC_FIREBASE_USER_TENANT_ID
      break
  }
  if (!tenantId) {
    throw new Error(`Tenant ID is undefined for tenant: ${tenant}`)
  }

  return tenantId
}

export const getFirebasePublicAPIKey = () =>
  process.env.NEXT_PUBLIC_FIREBASE_PUBLIC_API_KEY

export const getIsDebug = () => core.isDebug
