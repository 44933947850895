import { core } from './config'

export const removeCookie = async () => {
  const url = core.logoutUrl
  const init = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
  }

  return fetch(url, init)
    .then((res) => res.json())
    .then((data) => data)
}

export default removeCookie
