import { createSelector } from '@reduxjs/toolkit'
import { RootState } from 'src/lib/store'
import { Directory, DirectoryId, MediaItem } from '../types'
import { buildGetMediaItemsPayload } from './utils'

export const selectMediaItem = (
  state: RootState,
  mediaItemId: DirectoryId,
  parentDirectoryId: DirectoryId,
  mediaLibraryApi
): MediaItem | null =>
  createSelector(
    mediaLibraryApi.endpoints.getMediaItems.select(
      buildGetMediaItemsPayload(parentDirectoryId)
    ),
    ({ data }) => {
      if (!data) return null
      return data.mediaItems.find((item) => item.id === mediaItemId) ?? null
    }
  )(state)

export const selectDirectory = (
  state: RootState,
  directoryId: DirectoryId,
  parentDirectoryId: DirectoryId,
  mediaLibraryApi
): Directory | null =>
  createSelector(
    mediaLibraryApi.endpoints.getMediaItems.select(
      buildGetMediaItemsPayload(parentDirectoryId)
    ),
    ({ data }) => {
      if (!data) return null
      return data.directories.find((dir) => dir.id === directoryId) ?? null
    }
  )(state)
