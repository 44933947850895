import { Component, ComponentData } from '@features/projects/lib/base/Component'
import { Pad, PadData } from '@features/projects/lib/base/Pad'

/* eslint-disable react/sort-comp */
interface LiveInputSourceData extends ComponentData {
  liveInputId: string
  output: PadData
}
export class LiveInputSource extends Component {
  type = 'liveInputSource'

  liveInputId: string

  output: Pad

  constructor(data: LiveInputSourceData) {
    super(data)
    this.liveInputId = data.liveInputId
    this.output = new Pad(data.output)
  }

  toData() {
    const commonData = super.toData()
    return {
      ...commonData,
      type: this.getType(),
      output: this.output.toData(),
      liveInputId: this.liveInputId,
    }
  }

  static create(liveInputId = '', displayName?: string) {
    const data = {
      liveInputId,
      displayName,
      output: new Pad(),
    }
    return new LiveInputSource(data)
  }

  patch(data: Partial<LiveInputSourceData>) {
    super.patch(data)
    const { output, ...rest } = data
    this.output.patch(output as Pad)
    Object.assign(this, { ...rest })
  }
}
