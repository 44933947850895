import { z } from 'zod'

export const cropSchema = z
  .object({
    left: z.number().min(0).max(1).default(0),
    right: z.number().min(0).max(1).default(0),
    bottom: z.number().min(0).max(1).default(0),
    top: z.number().min(0).max(1).default(0),
  })
  .refine(
    (data) => data.top + data.bottom < 1 && data.left + data.right < 1,
    "Values can't exceed 100%"
  )
export type Crop = z.infer<typeof cropSchema>
