export default {
  colors: {
    'bg-workspace': {
      default: 'gray.50',
      _dark: 'gray.850',
    },
    'bg-canvas': {
      default: 'gray.50',
      _dark: 'gray.800',
    },
    'bg-surface': {
      default: 'white',
      _dark: 'gray.700',
    },
    'bg-subtle': {
      default: 'gray.50',
      _dark: 'gray.600',
    },
    'bg-muted': {
      default: 'gray.100',
      _dark: 'gray.500',
    },

    'fg.default': {
      default: 'gray.900',
      _dark: 'white',
    },
    'fg.emphasized': {
      default: 'gray.700',
      _dark: 'gray.200',
    },
    'fg.muted': {
      default: 'gray.600',
      _dark: 'gray.300',
    },
    'fg.subtle': {
      default: 'gray.500',
      _dark: 'gray.400',
    },
    'fg.inverted': {
      default: 'white',
      _dark: 'gray.950',
    },

    default: {
      default: 'gray.900',
      _dark: 'white',
    },
    inverted: {
      default: 'white',
      _dark: 'gray.900',
    },
    emphasized: {
      default: 'gray.700',
      _dark: 'gray.100',
    },
    muted: {
      default: 'gray.600',
      _dark: 'gray.300',
    },
    subtle: {
      default: 'gray.500',
      _dark: 'gray.400',
    },
    border: {
      default: 'gray.200',
      _dark: 'gray.700',
    },
    accent: {
      default: 'brand.500',
      _dark: 'brand.200',
    },
    success: {
      default: 'green.600',
      _dark: 'green.200',
    },
    error: {
      default: 'red.600',
      _dark: 'red.200',
    },
    'bg.accent.default': 'brand.600',
    'bg.accent.subtle': 'brand.500',
    'bg.accent.muted': 'brand.400',

    'fg.accent.subtle': 'brand.100',
    'fg.accent.muted': 'brand.50',
    'fg.accent.default': 'white',
  },
}
