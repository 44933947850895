export function createPadReferenceId(
  padReference: {
    componentId: string
    padName: string
  } | null
) {
  if (!padReference) {
    return null
  }
  return `${padReference.componentId}.${padReference.padName}`
}

export function createLinkId(link: {
  source: { componentId: string; padName: string }
  target: { componentId: string; padName: string }
}) {
  return `${link.source.componentId}.${link.source.padName}:${link.target.componentId}.${link.target.padName}`
}
