import {
  mode,
  StyleFunctionProps,
  transparentize,
} from '@chakra-ui/theme-tools'
import { createMultiStyleConfigHelpers } from '@chakra-ui/react'
import { numberInputAnatomy } from '@chakra-ui/anatomy'

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(numberInputAnatomy.keys)

const variants = {
  outline: (props: StyleFunctionProps) =>
    definePartsStyle({
      field: {
        borderRadius: 'sm',
        bg: mode('white', 'gray.800')(props),
        _hover: { borderColor: mode('gray.300', 'gray.600')(props) },
        _focus: {
          borderColor: mode('brand.500', 'brand.200')(props),
          boxShadow: mode(
            `0px 0px 0px 1px ${transparentize(`brand.500`, 1.0)(props.theme)}`,
            `0px 0px 0px 1px ${transparentize(`brand.200`, 1.0)(props.theme)}`
          )(props),
        },
      },
      addon: {
        borderRadius: 'sm',
        bg: mode('gray.50', 'gray.700')(props),
      },
    }),
  'outline-on-accent': (props: StyleFunctionProps) =>
    definePartsStyle({
      field: {
        bg: 'white',
        borderRadius: 'sm',
        color: 'gray.900',
        borderWidth: '1px',
        borderColor: 'brand.50',
        _placeholder: {
          color: 'gray.500',
        },
        _hover: {
          borderColor: 'brand.100',
        },
        _focus: {
          borderColor: 'brand.200',
          boxShadow: `0px 0px 0px 1px ${transparentize(
            `brand.200`,
            1.0
          )(props.theme)}`,
        },
      },
    }),
  filled: (props: StyleFunctionProps) => {
    if (props.colorScheme === 'gray') {
      return {
        field: {
          bg: mode('white', 'gray.800')(props),
          _hover: {
            borderColor: mode('gray.200', 'gray.700')(props),
            bg: mode('white', 'gray.700')(props),
          },
          _focus: {
            borderColor: 'accent',
            bg: mode('white', 'gray.800')(props),
          },
        },
      }
    }
    return definePartsStyle({
      field: {
        bg: 'bg-accent-subtle',
        color: 'on-accent',
        _placeholder: {
          color: 'on-accent',
        },
        _hover: {
          borderColor: 'brand.400',
          bg: 'bg-accent-subtle',
        },
        _focus: {
          bg: 'bg-accent-subtle',
          borderColor: 'brand.300',
        },
      },
    })
  },
}

const sizes = {
  xs: {
    field: {
      fontSize: '0.8rem',
      height: '0.7rem',
      borderRadius: 'xs',
      padding: '0.7rem 0.3rem',
      marginBottom: '0.2rem',
      marginTop: '0',
    },
    stepper: {
      marginTop: 0,
      border: 0,
      padding: 0,
      margin: 0,
    },
  },
  sm: {
    field: {
      fontSize: '1.2rem',
      height: '1.5rem',
      borderRadius: 'xs',
      padding: '0.85rem 0.3rem',
      marginBottom: '0.2rem',
      marginTop: '0',
    },
    stepper: {
      margin: 0,
      border: 0,
      padding: 0,
    },
  },
  lg: {
    field: {
      fontSize: 'md',
      borderRadius: 'sm',
    },
    stepper: {
      margin: 0,
      border: 0,
      padding: 0,
    },
  },
}

export default defineMultiStyleConfig({
  variants,
  sizes,
  defaultProps: {
    size: 'sm',
    colorScheme: 'gray',
  },
})
