import { ConnectionStateEnum } from '@hooks/useWebRTCMediaServer/mediasoupOrchestrator/ConnectionState'
import {
  createEntityAdapter,
  createSelector,
  createSlice,
} from '@reduxjs/toolkit'
import { RootState } from '@lib/store'
import { WorkflowSessionId } from '@customTypes/cloudmix/workflow'

export interface IncomingDataChannel {
  id: string
  peerId: string
  workflowSessionId: WorkflowSessionId
  connectionState: ConnectionStateEnum
}

const incomingDataChannelAdapter = createEntityAdapter<IncomingDataChannel>()

const incomingDataChannelSlice = createSlice({
  name: 'incomingDataChannel',
  initialState: incomingDataChannelAdapter.getInitialState(),
  reducers: {
    addIncomingDataChannel: incomingDataChannelAdapter.addOne,
    removeIncomingDataChannel: incomingDataChannelAdapter.removeOne,
    addManyIncomingDataChannels: incomingDataChannelAdapter.addMany,
    updateIncomingDataChannel: incomingDataChannelAdapter.updateOne,
    updateIncomingDataChannelConnectionState: (state, action) => {
      const { id, connectionState } = action.payload
      const incomingDataChannel = state.entities[id]
      if (incomingDataChannel) {
        incomingDataChannel.connectionState = connectionState
      }
    },
  },
})

export const {
  addIncomingDataChannel,
  removeIncomingDataChannel,
  addManyIncomingDataChannels,
  updateIncomingDataChannel,
  updateIncomingDataChannelConnectionState,
} = incomingDataChannelSlice.actions

export default incomingDataChannelSlice.reducer

const incomingDataChannelSelectors =
  incomingDataChannelAdapter.getSelectors<RootState>(
    (state) => state.projectWebRTCIncomingData
  )

export const selectIncomingDataChannels = incomingDataChannelSelectors.selectAll

export const selectIncomingDataChannelById = (state, incomingDataChannelId) =>
  incomingDataChannelSelectors.selectById(state, incomingDataChannelId)

export const selectIncomingDataChannelsByPeerId = (state, peerId) =>
  incomingDataChannelSelectors
    .selectAll(state)
    .filter((incomingDataChannel) => incomingDataChannel.peerId === peerId)

export const selectIncomingDataChannelsByWorkflowSessionId = createSelector(
  [selectIncomingDataChannels, (state, workflowSessionId) => workflowSessionId],
  (channels, workflowSessionId) =>
    channels.filter(
      (incomingDataChannel) =>
        incomingDataChannel.workflowSessionId === workflowSessionId
    )
)
