import { z } from 'zod'

export const STREAMING_PROTOCOL_TYPES = {
  rtmp: 'rtmp',
  srt: 'srt',
} as const

export const streamingProtocolTypesSchema = z.nativeEnum(
  STREAMING_PROTOCOL_TYPES
)
export type StreamingProtocolTypes = z.infer<
  typeof streamingProtocolTypesSchema
>

export const rtmpUrlSchema = z
  .string()
  .trim()
  .regex(
    /^(rtmp|rtmps):\/\/([a-zA-Z0-9.-]+)(:[0-9]+)?\/[a-zA-Z0-9/_-]+$/,
    'Url must start with rtmp:// or rtmps:// and follow the pattern protocol://host[:port]/app'
  )
  .transform((url) => url.replace(/\/$/, ''))

export const rtmpStreamKeySchema = z
  .string()
  .min(1, 'Stream key cannot be empty')

export const rtmpStreamingProtocolSchema = z.object({
  type: z
    .literal(STREAMING_PROTOCOL_TYPES.rtmp)
    .default(STREAMING_PROTOCOL_TYPES.rtmp),
  url: rtmpUrlSchema.default('rtmp://'),
  streamKey: rtmpStreamKeySchema,
})
export type RtmpStreamingProtocol = z.infer<typeof rtmpStreamingProtocolSchema>

export const srtUrlSchema = z
  .string()
  .regex(
    /^srt:\/\/([a-zA-Z0-9-_.]+):(\d+)(\/\S*)?(\?\S*)?$/,
    "Url must start with 'srt://' and follow the pattern srt://host:port[/path][?query]"
  )
  .transform((v) => v ?? 'srt://')

export const srtStreamIdSchema = z
  .string()
  .nullable()
  .transform((v) => v ?? null)

export const srtPassphraseSchema = z
  .string()
  .nullable()
  .transform((v) => v ?? null)

export const srtStreamingProtocolSchema = z.object({
  type: z
    .literal(STREAMING_PROTOCOL_TYPES.srt)
    .default(STREAMING_PROTOCOL_TYPES.srt),
  passphrase: srtPassphraseSchema.default(null),
  streamId: srtStreamIdSchema.default(null),
  latency: z
    .number()
    .min(1)
    .max(10000)
    .default(1000)
    .transform((v) => v ?? 1000),
  url: srtUrlSchema.default('srt://'),
})
export type SrtStreamingProtocol = z.infer<typeof srtStreamingProtocolSchema>

export const streamingProtocolSchema = z.discriminatedUnion('type', [
  rtmpStreamingProtocolSchema,
  srtStreamingProtocolSchema,
])
export type StreamingProtocol = z.infer<typeof streamingProtocolSchema>

export const SRT_MODE_ENUM = {
  CALLER: 'caller',
  // LISTENER: 'listener', //TODO(ajb): Uncomment once listener is supported
} as const
export const srtModeSchema = z.nativeEnum(SRT_MODE_ENUM)
export type SrtMode = typeof srtModeSchema['_type']
