import { Box, Card, CardBody, CardFooter, Heading } from '@chakra-ui/react'
import { FC, PropsWithChildren, ReactElement } from 'react'

// Pulled from FallbackRender from @sentry/nextjs
interface SentryErrorData {
  error?: any // This is unknown in sentry. I've chosen any to avoid 'as Error' or 'as string'
  componentStack?: string
  eventId?: string
  resetError?: () => void
}

export interface FallbackProps extends SentryErrorData {
  title?: string
  actionButton?: ReactElement
}

export const ErrorFallback: FC<PropsWithChildren<FallbackProps>> = ({
  title,
  actionButton,
  error,
  children,
  ...rest
}) => (
  <Card variant={'outline'} mt={3}>
    <CardBody>
      <Heading size="md">{title || 'There was a problem'}</Heading>
      <Box py="2">{children}</Box>
      {error && <pre>{error.message}</pre>}
    </CardBody>
    {actionButton && <CardFooter>{actionButton}</CardFooter>}
  </Card>
)
