import { core } from './config'

export const setCookie = async (jwt: string) => {
  const url = core.loginUrl
  const init = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: jwt,
    },
  }

  return fetch(url, init).then((res) => {
    if (!res.ok) {
      throw new Error('Failed to set cookie')
    }
    return res.json()
  })
}

export default setCookie
