import { Operations } from './Operations'
import { PermissionType } from './PermissionType'

/* eslint-disable no-underscore-dangle */
export class PermissionRule {
  constructor(
    private readonly _type: PermissionType,
    private readonly _resourcePattern: string[],
    private readonly _operations: Operations
  ) {}

  get type(): PermissionType {
    return this._type
  }

  get resourcePattern(): string[] {
    return this._resourcePattern
  }

  get operations(): Operations {
    return this._operations
  }
}
/* eslint-enable no-underscore-dangle */
