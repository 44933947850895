import { Mappable } from '@features/projects/lib/base/Mappable'
/* eslint-disable react/sort-comp */
interface LinkData {
  source: {
    componentId: string
    padName: string
  }
  target: {
    componentId: string
    padName: string
  }
}

export class Link implements Mappable<LinkData> {
  source: {
    componentId: string
    padName: string
  }

  target: {
    componentId: string
    padName: string
  }

  constructor(data: LinkData) {
    this.source = data.source
    this.target = data.target
  }

  public get linkId() {
    return `${this.source.componentId}.${this.source.padName}:${this.target.componentId}.${this.target.padName}`
  }

  hasConnection(sourceComponentId: string, targetComponentId: string) {
    return (
      this.source.componentId === sourceComponentId &&
      this.target.componentId === targetComponentId
    )
  }

  linkExists(
    sourceComponentId: string,
    sourcePadName: string,
    targetComponentId: string,
    targetPadName: string
  ) {
    return (
      this.source.componentId === sourceComponentId &&
      this.source.padName === sourcePadName &&
      this.target.componentId === targetComponentId &&
      this.target.padName === targetPadName
    )
  }

  toData(): LinkData {
    return {
      source: this.source,
      target: this.target,
    }
  }
}
