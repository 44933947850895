import { RootState } from '@lib/store'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { mediaLibraryApi } from './api'
import { getRootDirectoryId } from './api/utils'
import {
  DirectoryId,
  MediaItemId,
  LibraryItem,
  LibraryItemId,
  Directory,
} from './types'

export type MediaLibrarySortByTypes = 'type' | 'name' | 'size'

export interface MediaLibrarySort {
  by: MediaLibrarySortByTypes
  order: 'asc' | 'desc'
}

interface MediaLibraryState {
  sort: MediaLibrarySort
  globalSearch: string
  openDirectoryId: DirectoryId
  directoryPath: Directory[]
  selectedItems: LibraryItem[]
  uploadProgress: {
    totalParts: number
    completedParts: number
  }
}

const initialState: MediaLibraryState = {
  sort: { by: 'name', order: 'asc' },
  globalSearch: '',
  openDirectoryId: getRootDirectoryId(),
  directoryPath: [],
  selectedItems: [],
  uploadProgress: {
    totalParts: 0,
    completedParts: 0,
  },
}

const mediaLibrarySlice = createSlice({
  name: 'mediaLibrary',
  initialState,
  reducers: {
    setSort(state, action: PayloadAction<MediaLibrarySort>) {
      state.sort = action.payload
    },
    setGlobalSearch(state, action: PayloadAction<string>) {
      state.globalSearch = action.payload
    },
    setOpenDirectoryId(state, action: PayloadAction<DirectoryId>) {
      state.openDirectoryId = action.payload
      state.directoryPath = []
    },
    setDirectoryPath(state, action: PayloadAction<Directory[]>) {
      state.directoryPath = action.payload
    },
    openDirectory(state, action: PayloadAction<Directory>) {
      const parentDirectory = state.directoryPath.at(-1)
      if (!parentDirectory) return

      const hasDirectory = parentDirectory.directories.find(
        ({ id }) => id === action.payload.id
      )
      if (!hasDirectory) return

      state.directoryPath.push(action.payload)
      state.openDirectoryId = action.payload.id
      state.selectedItems = []
    },
    changeDirectory(state, action: PayloadAction<Directory[]>) {
      state.directoryPath = action.payload
      // If we're changing to no directoryPath, then we should reset openDirectoryId to root
      state.openDirectoryId = action.payload.at(-1)?.id ?? getRootDirectoryId()
      state.selectedItems = []
    },
    openParentDirectory(state) {
      const parentDirectory = state.directoryPath.at(-2)
      // DISCUSSION: silently fail or throw error?
      if (parentDirectory === undefined) return

      state.openDirectoryId = parentDirectory.id
      state.selectedItems = []
      state.directoryPath.pop()
    },
    setSelectedItems(state, action: PayloadAction<LibraryItem[]>) {
      state.selectedItems = action.payload
    },
    addSelectedItem(state, action: PayloadAction<LibraryItem>) {
      state.selectedItems.push(action.payload)
    },
    removeSelectedItem(state, action: PayloadAction<LibraryItemId>) {
      state.selectedItems = state.selectedItems.filter(
        (item) => item.id !== action.payload
      )
    },
    setUploadProgress(
      state,
      action: PayloadAction<MediaLibraryState['uploadProgress']>
    ) {
      state.uploadProgress = action.payload
    },
    incrementCompleteParts(state) {
      state.uploadProgress.completedParts += 1
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      mediaLibraryApi.endpoints.getMediaItems.matchFulfilled,
      (state, { payload, meta }) => {
        // If there is no directoryPath, add the root directory, the api only returns { directories, mediaItems }
        // The id is added in transformResponse and we add the name 'Root' here.
        if (state.directoryPath.length === 0) {
          const directory = { ...payload, name: 'Root' } as Directory
          state.directoryPath.push(directory)
          const openDirectoryId =
            meta.arg.originalArgs?.id ?? getRootDirectoryId()
          state.openDirectoryId = openDirectoryId
        }
      }
    )
  },
})

export const selectOpenDirectoryId = ({
  mediaLibrary,
}: RootState): DirectoryId => mediaLibrary.openDirectoryId

export const selectItemFromSelectedItems = (
  { mediaLibrary }: RootState,
  id: MediaItemId | DirectoryId
): LibraryItem | undefined =>
  mediaLibrary.selectedItems.find((item) => item.id === id)

export const selectIsLibraryItemSelected = (
  state: RootState,
  id: MediaItemId | DirectoryId
): boolean => selectItemFromSelectedItems(state, id) !== undefined

export const selectParentDirectory = ({
  mediaLibrary,
}: RootState): Directory | undefined => mediaLibrary.directoryPath.at(-2)

export const {
  setSort,
  setGlobalSearch,
  setOpenDirectoryId,
  setDirectoryPath,
  openDirectory,
  changeDirectory,
  openParentDirectory,
  setSelectedItems,
  addSelectedItem,
  removeSelectedItem,
  setUploadProgress,
  incrementCompleteParts,
} = mediaLibrarySlice.actions

export default mediaLibrarySlice.reducer
