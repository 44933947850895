export enum ConnectionStateEnum {
  Idle = 'idle',
  Connecting = 'connecting',
  Connected = 'connected',
  Authenticating = 'authenticating',
  Authenticated = 'authenticated',
  Closed = 'closed',
  Error = 'error',
  Paused = 'paused',
}

export class ConnectionState {
  private connectionState = ConnectionStateEnum.Connecting

  setState(state: ConnectionStateEnum): void {
    this.connectionState = state
  }

  getState(): ConnectionStateEnum {
    return this.connectionState
  }

  isState(state: ConnectionStateEnum): boolean {
    return this.connectionState === state
  }

  static isBeforeAuthButNotClosed(state: ConnectionStateEnum): boolean {
    return (
      state === ConnectionStateEnum.Connecting ||
      state === ConnectionStateEnum.Connected ||
      state === ConnectionStateEnum.Idle
    )
  }

  static isState(
    state: ConnectionStateEnum,
    compare: ConnectionStateEnum
  ): boolean {
    return state === compare
  }

  static isOneOfStates(
    state: ConnectionStateEnum,
    compare: ConnectionStateEnum[]
  ): boolean {
    return compare.includes(state)
  }
}
