import { createContext, useContext, useMemo } from 'react'
import { useSelectedOrgContext } from '@features/organisations/contexts/ManageSelectedOrg'
import { SerializedError } from '@reduxjs/toolkit'
import { FetchBaseQueryError } from '@reduxjs/toolkit/query/react'
import { useUser } from '@lib/firebase/provider'
import { skipToken } from '@reduxjs/toolkit/query'
import { Permission, useAuthentication } from '@lib/permissions'

type PermissionsCheckerContextProps = {
  isLoading: boolean
  isError: boolean
  error: FetchBaseQueryError | SerializedError | undefined
  isPermitted: (permission: Permission) => boolean
  hasPermissions: (permissions: Permission[]) => boolean
}

const PermissionsCheckerContext =
  createContext<PermissionsCheckerContextProps | null>(null)

const PermissionsCheckerProvider = ({ children }) => {
  const { orgId } = useSelectedOrgContext()
  const { isRequestComplete } = useUser()

  const { isLoading, isError, error, isPermitted, hasPermissions } =
    useAuthentication({
      // Wait for the user to be loaded before checking permissions
      orgId: !isRequestComplete ? null : orgId,
    })

  const value = useMemo(
    () => ({
      isLoading,
      isError,
      error,
      isPermitted,
      hasPermissions,
    }),
    [isLoading, isError, error, isPermitted, hasPermissions]
  )

  return (
    <PermissionsCheckerContext.Provider value={value}>
      {children}
    </PermissionsCheckerContext.Provider>
  )
}

const usePermissionsCheckerContext = () => {
  const context = useContext(PermissionsCheckerContext)

  if (!context) {
    throw new Error(
      'usePermissionsCheckerContext must be used within a PermissionsCheckerProvider'
    )
  }

  return context
}

export { PermissionsCheckerProvider, usePermissionsCheckerContext }
