import { Directory, DirectoryId } from '../types'

export const ROOT_DIRECTORY_GUUID = '00000000-0000-0000-0000-000000000000'

export const getRootDirectoryId = (): string => ROOT_DIRECTORY_GUUID

export const isRootDirectoryId = (directoryId: DirectoryId): boolean =>
  directoryId === ROOT_DIRECTORY_GUUID

export const buildGetMediaItemsPayload = (
  directoryId: DirectoryId,
  maxDepth = 2
) =>
  isRootDirectoryId(directoryId)
    ? { maxDepth }
    : {
        id: directoryId,
        maxDepth,
      }

export const recursivelyAddParentDirectoryId = (
  directory: Directory,
  parentDirectoryId?: DirectoryId
): Directory => {
  const dir = {
    ...directory,
    directories: directory.directories.map((childDirectory) =>
      recursivelyAddParentDirectoryId(childDirectory, directory.id)
    ),
    mediaItems: directory.mediaItems.map((mediaItem) => ({
      ...mediaItem,
      parentDirectoryId: directory.id,
    })),
  }
  if (parentDirectoryId) dir.parentDirectoryId = parentDirectoryId
  return dir
}

const convertDirectoryToMediaLibrary = (directory: Directory) => ({
  directories: directory.directories,
  mediaItems: directory.mediaItems,
  id: directory.id,
})

const updateMediaItemsCache = (directory: Directory, mediaLibraryApi) =>
  mediaLibraryApi.util.updateQueryData(
    'getMediaItems',
    buildGetMediaItemsPayload(directory.id),
    // Draft is Immer
    (draft) => {
      // eslint-disable-next-line no-param-reassign
      draft = convertDirectoryToMediaLibrary(directory)
    }
  )

export const recursivelyUpdateGetMediaItemsCache = (
  directory: Directory,
  mediaLibraryApi,
  dispatch
) => {
  dispatch(updateMediaItemsCache(directory, mediaLibraryApi))
  directory.directories.forEach((childDirectory) =>
    recursivelyUpdateGetMediaItemsCache(
      childDirectory,
      mediaLibraryApi,
      dispatch
    )
  )
}
