import {
  Action,
  combineReducers,
  configureStore,
  ThunkAction,
} from '@reduxjs/toolkit'
import { setupListeners } from '@reduxjs/toolkit/query'
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
  createMigrate,
} from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import storageSession from 'redux-persist/lib/storage/session'
import { userManagementApi } from '@features/users/api'
import { cloudmixApi } from '@features/cloudmixApi/api'
import usageReducer from '@features/usage/slice'
import commentaryReducer from '@features/commentary/slice'
import checkoutReducer from '@features/signup/slice'
import lobbyReducer from '@features/lobby/slice'
import selectedOrgIdReducer from '@features/organisations/slice'
import mediaLibraryReducer from '@features/mediaLibrary/slice'
import { mediaLibraryApi } from '@features/mediaLibrary/api'
import projectReducer from '@features/projects/slices/project/slice'
import { mediaProjectsApi } from '@features/mediaProjectsApi/api'
import workflowBuilderReducer from '@features/projects/slices/workflow/builder/slice'
import projectSocketReducer from '@features/projects/slices/projectSocket/slice'
import controlRoomTemplateReducer from '@features/projects/slices/controlRoomTemplate/slice'
import projectWebRTCSessionReducer from '@features/projects/slices/projectWebRTCSession/slice'
import projectWebRTCStreamReducer from '@features/projects/slices/projectWebRTCSession/stream/slice'
import projectWebRTCPeerReducer from '@features/projects/slices/projectWebRTCSession/peer/slice'
import projectWebRTCIncomingDataReducer from '@features/projects/slices/projectWebRTCSession/incomingData/slice'
import projectWebRTCOutgoingDataReducer from '@features/projects/slices/projectWebRTCSession/outgoingData/slice'
import apiKeysReducer from '@features/apiKeys/slice'
import layoutReducer from '@features/layout/slice'
import { migrations } from '@lib/migrations'

const persistConfig = {
  key: 'root',
  version: 2,
  storage,
  whitelist: ['commentary', 'layout', 'usage', 'project'],
  migrate: createMigrate(migrations, { debug: false }),
  autoMergeLevel2: true,
}

const sessionPersistConfig = {
  key: 'commentary',
  storage: storageSession,
}

const appReducer = combineReducers({
  [userManagementApi.reducerPath]: userManagementApi.reducer,
  [cloudmixApi.reducerPath]: cloudmixApi.reducer,
  [mediaProjectsApi.reducerPath]: mediaProjectsApi.reducer,
  [mediaLibraryApi.reducerPath]: mediaLibraryApi.reducer,
  usage: usageReducer,
  commentary: persistReducer(sessionPersistConfig, commentaryReducer),
  checkout: checkoutReducer,
  lobby: lobbyReducer,
  selectedOrgId: selectedOrgIdReducer,
  mediaLibrary: mediaLibraryReducer,
  project: projectReducer,
  workflowBuilder: workflowBuilderReducer,
  projectSocket: projectSocketReducer,
  controlRoomTemplate: controlRoomTemplateReducer,
  projectWebRTCSession: projectWebRTCSessionReducer,
  projectWebRTCStream: projectWebRTCStreamReducer,
  projectWebRTCPeer: projectWebRTCPeerReducer,
  projectWebRTCIncomingData: projectWebRTCIncomingDataReducer,
  projectWebRTCOutgoingData: projectWebRTCOutgoingDataReducer,
  apiKeys: apiKeysReducer,
  layout: layoutReducer,
})

export const SIGNOUT_REQUEST = {
  type: 'SIGNOUT_REQUEST',
}

const rootReducer = (state, action) => {
  if (action.type === 'SIGNOUT_REQUEST') {
    storage.removeItem('persist:root')
    // eslint-disable-next-line no-param-reassign
    state = {} as RootState
    return appReducer(state, action)
  }

  return appReducer(state, action)
}

type RootReducer = ReturnType<typeof rootReducer>

const persistedReducer = persistReducer<RootReducer>(persistConfig, rootReducer)

export const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== 'production',
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).concat(
      userManagementApi.middleware,
      cloudmixApi.middleware,
      mediaLibraryApi.middleware,
      mediaProjectsApi.middleware
    ),
})

export const persistor = persistStore(store)
export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>
setupListeners(store.dispatch)
