const colors = {
  primary: {
    '50': '#a6cfff',
    '100': '#73b4ff',
    '200': '#5aa6ff',
    '300': '#4098ff',
    '400': '#278bff',
    '500': '#0070F3',
    '600': '#0058c0',
    '700': '#004da6',
    '800': '#00418d',
    '900': '#00295a',
  },
  secondary: {
    '50': '#9ff4c3',
    '100': '#71efa7',
    '200': '#5aec99',
    '300': '#43e98b',
    '400': '#2ce77d',
    '500': '#17C964',
    '600': '#129b4d',
    '700': '#0f8442',
    '800': '#0d6d36',
    '900': '#074020',
  },
  transparent: 'transparent',
  current: 'currentColor',
  black: '#16161D',
  white: '#FFFFFF',
  ivory: {
    '50': '#ffffff',
    '100': '#ffffff',
    '200': '#fafafa',
    '300': '#ffffff',
    '400': '#ffffff',
    '500': '#F5F5F5',
    '600': '#ffffff',
    '700': '#ffffff',
    '800': '#ffffff',
    '900': '#ffffff',
  },
  whiteAlpha: {
    '50': 'rgba(255, 255, 255, 0.04)',
    '100': 'rgba(255, 255, 255, 0.06)',
    '200': 'rgba(255, 255, 255, 0.08)',
    '300': 'rgba(255, 255, 255, 0.16)',
    '400': 'rgba(255, 255, 255, 0.24)',
    '500': 'rgba(255, 255, 255, 0.36)',
    '600': 'rgba(255, 255, 255, 0.48)',
    '700': 'rgba(255, 255, 255, 0.64)',
    '800': 'rgba(255, 255, 255, 0.80)',
    '900': 'rgba(255, 255, 255, 0.92)',
  },
  blackAlpha: {
    '50': 'rgba(0, 0, 0, 0.04)',
    '100': 'rgba(0, 0, 0, 0.06)',
    '200': 'rgba(0, 0, 0, 0.08)',
    '300': 'rgba(0, 0, 0, 0.16)',
    '400': 'rgba(0, 0, 0, 0.24)',
    '500': 'rgba(0, 0, 0, 0.36)',
    '600': 'rgba(0, 0, 0, 0.48)',
    '700': 'rgba(0, 0, 0, 0.64)',
    '800': 'rgba(0, 0, 0, 0.80)',
    '900': 'rgba(0, 0, 0, 0.92)',
  },
  gray: {
    '50': '#F7FAFC',
    '100': '#EDF2F7',
    '200': '#E2E8F0',
    '300': '#CBD5E0',
    '400': '#A0AEC0',
    '500': '#718096',
    '600': '#4A5568',
    '700': '#2D3748',
    '750': '#242c3a',
    '800': '#1A202C',
    '850': '#16181e',
    '900': '#111111',
  },
  red: {
    '50': '#fbbcd2',
    '100': '#f98cb2',
    '200': '#f774a2',
    '300': '#f65c92',
    '400': '#f54381',
    '500': '#F21361',
    '600': '#c70b4d',
    '700': '#af0a43',
    '800': '#97083a',
    '900': '#660627',
  },
  orange: {
    '50': '#FCF0E9',
    '100': '#F6D6C0',
    '200': '#F1BC98',
    '300': '#EBA270',
    '400': '#E58848',
    '500': '#E06D1F',
    '600': '#B35719',
    '700': '#864213',
    '800': '#592C0D',
    '900': '#2D1606',
  },
  yellow: {
    '50': '#FBF5EA',
    '100': '#F4E4C3',
    '200': '#ECD29C',
    '300': '#E5C076',
    '400': '#DEAF4F',
    '500': '#D69D29',
    '600': '#AB7E21',
    '700': '#815E18',
    '800': '#563F10',
    '900': '#2B1F08',
  },
  green: {
    '50': '#9ff4c3',
    '100': '#71efa7',
    '200': '#5aec99',
    '300': '#43e98b',
    '400': '#2ce77d',
    '500': '#17C964',
    '600': '#129b4d',
    '700': '#0f8442',
    '800': '#0d6d36',
    '900': '#074020',
  },
  teal: {
    '50': '#abedde',
    '100': '#81e4cd',
    '200': '#6cdfc5',
    '300': '#57dabd',
    '400': '#42d6b5',
    '500': '#29BC9B',
    '600': '#209278',
    '700': '#1b7d67',
    '800': '#176856',
    '900': '#0e3e33',
  },
  blue: {
    '50': '#a6cfff',
    '100': '#73b4ff',
    '200': '#5aa6ff',
    '300': '#4098ff',
    '400': '#278bff',
    '500': '#0070F3',
    '600': '#0058c0',
    '700': '#004da6',
    '800': '#00418d',
    '900': '#00295a',
  },
  cyan: {
    '50': '#EDFDFD',
    '100': '#C4F1F9',
    '200': '#9DECF9',
    '300': '#76E4F7',
    '400': '#0BC5EA',
    '500': '#00B5D8',
    '600': '#00A3C4',
    '700': '#0987A0',
    '800': '#086F83',
    '900': '#065666',
  },
  purple: {
    '50': '#d2b4f0',
    '100': '#b98ae8',
    '200': '#ac74e4',
    '300': '#9f5fdf',
    '400': '#934adb',
    '500': '#7928CA',
    '600': '#60209f',
    '700': '#531b8a',
    '800': '#461775',
    '900': '#2d0f4a',
  },
  pink: {
    '50': '#fdeff4',
    '100': '#f7c2d4',
    '200': '#f4abc5',
    '300': '#f195b5',
    '400': '#ee7ea5',
    '500': '#E85186',
    '600': '#e22467',
    '700': '#d11c5b',
    '800': '#ba1951',
    '900': '#8d133e',
  },
}

export default colors
