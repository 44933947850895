import { useAuthenticateQuery } from '@features/organisations'
import { useCallback, useMemo } from 'react'
import { skipToken } from '@reduxjs/toolkit/query'
import { PermissionRule as PermissionRuleType } from '@features/organisations/types'
import { PermissionRule } from './PermissionRule'
import { PermissionsUtil } from './PermissionsUtil'
import { Permission } from './Permission'

export const useAuthentication = ({ orgId }: { orgId: string | null }) => {
  const { data, isLoading, isError, error } = useAuthenticateQuery(
    orgId ? { orgId } : skipToken
  )

  const isStaff = data?.isStaff
  const hasOrgAccess = data?.hasOrgAccess

  const permissionRulesJson: PermissionRuleType[] = useMemo(() => {
    if (isStaff) {
      return data?.staffPermissionRules
    }
    if (hasOrgAccess) {
      return data?.orgAccessDetails.permissionRules
    }
    return []
  }, [isStaff, hasOrgAccess, data])

  const resourceLimits = useMemo(() => {
    if (hasOrgAccess) {
      return data?.orgAccessDetails.resourceLimits || {}
    }
    return {}
  }, [hasOrgAccess, data])

  const permissionsRules = useMemo(() => {
    if (!permissionRulesJson) return null
    return permissionRulesJson.map(
      (rule) =>
        new PermissionRule(rule.type, rule.resourcePattern, rule.operations)
    )
  }, [permissionRulesJson])

  const isPermitted = useCallback(
    (permission: Permission) => {
      if (!permissionsRules) return false
      return PermissionsUtil.permissionIsGranted(permission, permissionsRules)
    },
    [permissionsRules]
  )

  const hasPermissions = useCallback(
    (permissions: Permission[]) => {
      if (!permissionsRules) return false
      return permissions.every((permission) =>
        PermissionsUtil.permissionIsGranted(permission, permissionsRules)
      )
    },
    [permissionsRules]
  )

  return {
    isLoading,
    isError,
    error,
    isPermitted,
    hasPermissions,
  }
}
