import { createSlice } from '@reduxjs/toolkit'
import { RootState } from '@lib/store'

type ControlRoomTemplateState = {
  selectedComponent: string | null
  selectedMixerInput: string | null
  sourceOrDestination: 'source' | 'destination' | null
}
export const initialState: ControlRoomTemplateState = {
  selectedComponent: null,
  selectedMixerInput: null,
  sourceOrDestination: null,
}

const controlRoomTemplate = createSlice({
  name: 'controlRoomTemplate',
  initialState,
  reducers: {
    setSelectedComponent: (state, action) => {
      state.selectedComponent = action.payload
    },
    setSelectedMixerInput: (state, action) => {
      state.selectedMixerInput = action.payload
    },
    setSelectComponentAndMixerInput: (state, action) => {
      state.selectedComponent = action.payload.component
      state.selectedMixerInput = action.payload.mixerInput
    },
    deselectComponentAndMixerInput: (state) => {
      state.selectedComponent = null
      state.selectedMixerInput = null
      state.sourceOrDestination = null
    },
    setSelectedSourceComponent: (state, action) => {
      state.selectedComponent = action.payload
      state.sourceOrDestination = 'source'
    },
    setSelectDestinationComponent: (state, action) => {
      state.selectedComponent = action.payload
      state.sourceOrDestination = 'destination'
    },
  },
})
export const {
  setSelectedComponent,
  setSelectedMixerInput,
  setSelectComponentAndMixerInput,
  deselectComponentAndMixerInput,
  setSelectedSourceComponent,
  setSelectDestinationComponent,
} = controlRoomTemplate.actions
export default controlRoomTemplate.reducer

export const selectSelectedComponent = (state: RootState) =>
  state.controlRoomTemplate.selectedComponent

export const selectSourceOrDestination = (state: RootState) =>
  state.controlRoomTemplate.sourceOrDestination
