import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import {
  indexedDBLocalPersistence,
  setPersistence,
  User,
  UserCredential,
  signInWithEmailAndPassword,
  Auth,
  signInWithRedirect,
  GoogleAuthProvider,
  getRedirectResult,
  signOut,
  onIdTokenChanged,
  onAuthStateChanged,
  beforeAuthStateChanged,
} from 'firebase/auth'
import * as Sentry from '@sentry/nextjs'
import { setCookie } from './setCookie'
import { removeCookie } from './removeCookie'
import { getClientTenantAuth } from './getClientTenantAuth'

type AuthUser = {
  id: string
  displayName?: string | null
  email?: string | null
  emailVerified?: boolean
  loading?: boolean
  photoURL?: string | null
  getIdToken: (forceRefresh: boolean) => Promise<string>
}

interface AuthContextProps {
  user: AuthUser | null
  loading: boolean
  logout: () => Promise<void | null>
  loginWithEmailAndPassword: (
    email: string,
    password: string
  ) => Promise<UserCredential> | null
  isInitialized: boolean
  loginWithGoogle: () => Promise<void> | null
  isRequestComplete: boolean
}

const AuthContext = createContext<AuthContextProps | undefined>(undefined)

interface AuthProviderProps {
  children: ReactNode
}

const AuthProvider = ({ children }: AuthProviderProps) => {
  const [user, setUser] = useState<null | AuthUser>(null)
  const [isInitialized, setIsInitialized] = useState<boolean>(false)
  const [isRequestComplete, setIsRequestComplete] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(true)
  const firebaseAuth = useRef<null | Auth>(null)

  useEffect(() => {
    if (!firebaseAuth.current) {
      const auth = getClientTenantAuth()
      firebaseAuth.current = auth

      setPersistence(auth, indexedDBLocalPersistence).catch((error) => {
        Sentry.captureException(error)
      })
      auth?.authStateReady().then(() => {
        setIsInitialized(true)
      })
    }
  }, [])

  useEffect(() => {
    // Subscribe to auth on mount
    const auth = firebaseAuth.current
    if (!auth) {
      return () => {}
    }
    let isCancelled = false

    const unsubscribeBeforeStateChange = beforeAuthStateChanged(
      auth,
      async () => {
        setLoading(true)
      }
    )

    const unsubscribeAuthStateChange = onAuthStateChanged(
      auth,
      async (u: User) => {
        if (u) {
          await getRedirectResult(auth)
        }
      }
    )

    const unsubscribeIdTokenChanged = onIdTokenChanged(
      auth,
      async (u: User) => {
        if (u) {
          const token = await u.getIdToken()
          if (token) {
            try {
              await setCookie(token)
            } catch (error) {
              await removeCookie()
              await signOut(auth)
              return
            }
          }
          setUser({
            id: u.uid,
            displayName: u?.displayName ?? null,
            email: u?.email,
            emailVerified: u?.emailVerified,
            photoURL: u?.photoURL,
            getIdToken: (forceRefresh: boolean) => u?.getIdToken(forceRefresh),
          })
        } else {
          setUser(null)
        }
        if (!isCancelled) {
          setIsRequestComplete(true)
        }
        setLoading(false)
      }
    )

    // Unsubscribe on cleanup
    return () => {
      unsubscribeBeforeStateChange()
      unsubscribeAuthStateChange()
      unsubscribeIdTokenChanged()
      isCancelled = true
    }
  }, [])

  const logout = useCallback(async () => {
    const auth = firebaseAuth.current
    if (!auth) {
      return null
    }
    setUser(null)
    await removeCookie()
    return signOut(auth)
  }, [])

  const loginWithEmailAndPassword = useCallback(
    (email: string, password: string) => {
      const auth = firebaseAuth.current
      if (!auth) {
        return null
      }

      return signInWithEmailAndPassword(auth, email, password)
    },
    []
  )

  const loginWithGoogle = useCallback(() => {
    const auth = firebaseAuth.current
    if (!auth) {
      return null
    }
    const provider = new GoogleAuthProvider()
    return signInWithRedirect(auth, provider)
  }, [])

  const value = useMemo(
    () => ({
      user,
      loading,
      logout,
      loginWithEmailAndPassword,
      loginWithGoogle,
      isInitialized,
      isRequestComplete,
    }),
    [
      user,
      loading,
      logout,
      loginWithEmailAndPassword,
      loginWithGoogle,
      isInitialized,
      isRequestComplete,
    ]
  )

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}

export const useUser = () => {
  const context = useContext(AuthContext)
  if (context === undefined) {
    throw new Error('useUser must be used within a AuthProvider')
  }
  return context
}

export { AuthProvider }
