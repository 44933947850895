import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export enum MenuSize {
  full = 'full',
  minimal = 'minimal',
}

interface LayoutState {
  menuHidden: boolean
  menuSize: MenuSize
}

export const initialState: LayoutState = {
  menuHidden: false,
  menuSize: MenuSize.full,
}

const layoutSlice = createSlice({
  name: 'layout',
  initialState,
  reducers: {
    setMenuSize: (state, action: PayloadAction<MenuSize>) => {
      state.menuSize = action.payload
    },
    setMenuHidden: (state, action: PayloadAction<boolean>) => {
      state.menuHidden = action.payload
    },
    toggleMenuVisibility: (state) => {
      state.menuHidden = !state.menuHidden
    },
    toggleMenuSize: (state) => {
      state.menuSize =
        state.menuSize === MenuSize.full ? MenuSize.minimal : MenuSize.full
    },
  },
})
export default layoutSlice.reducer

const selectMenuSize = (state: { layout: LayoutState }) => state.layout.menuSize
const selectMenuHidden = (state: { layout: LayoutState }) =>
  state.layout.menuHidden

export const {
  setMenuSize,
  setMenuHidden,
  toggleMenuVisibility,
  toggleMenuSize,
} = layoutSlice.actions
export { selectMenuSize, selectMenuHidden }
