import { useContext } from 'react'
import {
  ManageSelectedOrgContext,
  ManageSelectedOrgContextProps,
} from './Context'

export const useSelectedOrgContext = (): ManageSelectedOrgContextProps => {
  const context = useContext(ManageSelectedOrgContext)

  if (!context) {
    throw new Error(
      'useSelectedOrgContext must be used within a ManageSelectedOrgProvider'
    )
  }

  return context
}
