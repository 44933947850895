import { createAsyncThunk } from '@reduxjs/toolkit'
import { RootState } from '@lib/store'
import { Edge, Node } from '@xyflow/react'
import { v4 as uuidv4 } from 'uuid'
import {
  ContributorComponent,
  MixerComponent,
} from '@customTypes/cloudmix/sessions/components'
import { AudioVideoPad, Pad } from '@customTypes/cloudmix/sessions/pads'
import { ComponentNode } from '@customTypes/cloudmix/workflow'

export const PadType = {
  INPUTS: 'inputs',
  OUTPUTS: 'outputs',
} as const
export type PadTypes = typeof PadType[keyof typeof PadType]

type MixerOrContributorNode = Node<MixerComponent | ContributorComponent>

const tryGetNode = (
  nodes: ComponentNode[],
  componentId: string
): MixerOrContributorNode => {
  const node = nodes.find(({ id }) => id === componentId)
  if (!node) throw new Error('Node not found')

  return node as MixerOrContributorNode
}

const updateNode = (nodes, componentId, updatedNode) => [
  ...nodes.filter(({ id }) => id !== componentId),
  updatedNode,
]

const hasPad = (pads, padName: string) =>
  pads.find(({ name }) => name === padName) !== undefined

const buildReplacementNode = (
  node: Node,
  filteredPads: Pad[],
  padType: PadTypes
): Node => ({
  ...node,
  data: {
    ...node.data,
    [padType]: filteredPads,
  },
})

const filterOutConnectedEdges = (edges, padName: string, padType: PadTypes) =>
  edges.filter(({ sourceHandle, targetHandle }) =>
    padType === PadType.INPUTS
      ? targetHandle !== padName
      : sourceHandle !== padName
  )

export const removePadFromNodeAsync = createAsyncThunk<
  {
    nodes: ComponentNode[]
    edges: Edge[]
  },
  { componentId: string; padName: string; padType?: PadTypes },
  { state: RootState }
>(
  'workflowBuilder/removePadFromNodeAsync',
  async ({ componentId, padName, padType = PadType.INPUTS }, { getState }) => {
    const state = getState().workflowBuilder

    const currentNode = tryGetNode(
      state.nodes,
      componentId
    ) as MixerOrContributorNode
    if (!currentNode.data[padType])
      throw new Error('Pad type not found on node')

    const pads: AudioVideoPad[] = currentNode.data[padType] as AudioVideoPad[]
    if (!hasPad(pads, padName)) throw new Error('Pad not found')
    const filteredPads = pads?.filter((pad) => pad.name !== padName)

    const updatedNode = buildReplacementNode(currentNode, filteredPads, padType)

    const nodes = updateNode(state.nodes, componentId, updatedNode)

    const edges = filterOutConnectedEdges(state.edges, padName, padType)

    return { nodes, edges }
  }
)

const addPad = (
  node: MixerOrContributorNode,
  name: string,
  displayName: string,
  inputSettings,
  padType: PadTypes
) => [...node.data[padType], { name, displayName, ...inputSettings }]

export const addPadToNodeAsync = createAsyncThunk<
  {
    nodes: Node[]
  },
  {
    name?: string
    displayName?: string
    componentId: string
    padSettings?: { [key: string]: unknown }
    padType?: PadTypes
  },
  { state: RootState }
>(
  'workflowBuilder/addPadToNodeAsync',
  async (
    { name, displayName, componentId, padSettings, padType = PadType.INPUTS },
    { getState }
  ) => {
    const state = getState().workflowBuilder

    const currentNode = tryGetNode(state.nodes, componentId)
    const nodeData = currentNode.data as any // TODO: Fix this
    const pads = nodeData[padType] as AudioVideoPad[]
    const generatedName = name || uuidv4()
    const generatedDisplayName =
      displayName ||
      `${padType === PadType.INPUTS ? 'input' : 'output'} ${pads.length + 1}`

    const updatedPads = addPad(
      currentNode,
      generatedName,
      generatedDisplayName,
      padSettings,
      padType
    )

    const updatedNode = {
      ...currentNode,
      data: {
        ...nodeData,
        [padType]: updatedPads,
      },
    }

    const nodes = updateNode(state.nodes, componentId, updatedNode)

    return { nodes }
  }
)
