import { Component, ComponentData } from '@features/projects/lib/base/Component'
import { Pad, PadData } from '@features/projects/lib/base/Pad'

/* eslint-disable react/sort-comp */
interface WebSourceData extends ComponentData {
  url: string
  output: PadData
}
export class WebSource extends Component {
  type = 'webSource'

  output: Pad

  url: string

  constructor(data: WebSourceData) {
    super(data)
    this.output = new Pad(data.output)
    this.url = data.url
  }

  toData() {
    const commonData = super.toData()
    return {
      ...commonData,
      type: this.getType(),
      output: this.output.toData(),
      url: this.url,
    }
  }

  static fromData(data) {
    return new WebSource(data)
  }

  static create() {
    const data = {
      type: 'webSource',
      output: new Pad(),
      url: '',
    }

    return new WebSource(data)
  }

  patch(data: Partial<WebSourceData>) {
    super.patch(data)
    const { output, ...rest } = data
    this.output.patch(output as Pad)
    Object.assign(this, { ...rest })
  }
}
