import { Mappable } from '@features/projects/lib/base/Mappable'
import { Link } from '@features/projects/lib/base/Link'
import { Component } from '@features/projects/lib/base/Component'
import { Mixer } from '@features/projects/lib/components/Mixer'
import { RtmpDestination } from '@features/projects/lib/components/RtmpDestination'
import { TestSource } from '@features/projects/lib/components/TestSource'
import { ImageSource } from '@features/projects/lib/components/ImageSource'
import { WebSource } from '@features/projects/lib/components/WebSource'
import { LiveInputSource } from '@features/projects/lib/components/LiveInputSource'
import { Pad } from '@features/projects/lib/base/Pad'
import { Contributor } from '@features/projects/lib/components/Contributor'
import { Display } from '@features/projects/lib/components/Display'
import { SrtDestination } from '@features/projects/lib/components/SrtDestination'
import { Workflow as WorkflowType } from '@customTypes/cloudmix/workflow'
import { LiveOutputDestination } from '@features/projects/lib/components/LiveOutputDestination'
/* eslint-disable react/sort-comp */

const Components = {
  mixer: Mixer,
  rtmpDestination: RtmpDestination,
  srtDestination: SrtDestination,
  testSource: TestSource,
  imageSource: ImageSource,
  webSource: WebSource,
  liveInputSource: LiveInputSource,
  contributor: Contributor,
  display: Display,
  liveOutputDestination: LiveOutputDestination,
}

export class Workflow implements Mappable<unknown> {
  components: Component[]

  links: Link[]

  constructor(data) {
    this.components = data.components
      .filter(
        (component) => component && Object.hasOwn(component, 'componentId')
      )
      .map((component) => {
        if (!component) {
          return null
        }
        const Factory = Components[component.type]
        if (!Factory) {
          throw new Error(`Unknown component type ${component.type}`)
        }
        return new Factory(component)
      })
    this.links = data.links
      .filter(
        (link) =>
          link && Object.hasOwn(link, 'source') && Object.hasOwn(link, 'target')
      )
      .map((link) => new Link(link))
  }

  toData(): WorkflowType {
    return {
      components: this.components.map((component) => component.toData()),
      links: this.links.map((link) => link.toData()),
    }
  }

  link(
    sourceComponentId: string,
    sourcePadName: string,
    targetComponentId: string,
    targetPadName: string
  ) {
    if (
      this.links.some((link) =>
        link.linkExists(
          sourceComponentId,
          sourcePadName,
          targetComponentId,
          targetPadName
        )
      )
    ) {
      console.log('link already exists')
      return
    }
    const sourceComponent = this.getComponentById(sourceComponentId)
    const targetComponent = this.getComponentById(targetComponentId)
    if (!sourceComponent || !targetComponent) {
      console.log('no source or target component')
      return
    }

    const sourcePad = sourceComponent.getPadByName(sourcePadName)
    const targetPad = targetComponent.getPadByName(targetPadName)
    if (!sourcePad || !targetPad) {
      console.log('no source or target pad')
      return
    }

    const link = new Link({
      source: {
        componentId: sourceComponentId,
        padName: sourcePadName,
      },
      target: {
        componentId: targetComponentId,
        padName: targetPadName,
      },
    })
    this.links.push(link)
  }

  unlink(
    sourceComponentId: string,
    sourcePadName: string,
    targetComponentId: string,
    targetPadName: string
  ) {
    const linkToUnlink = this.links.find((link) =>
      link.linkExists(
        sourceComponentId,
        sourcePadName,
        targetComponentId,
        targetPadName
      )
    )

    if (!linkToUnlink) {
      return
    }
    if (
      linkToUnlink.source.padName === sourcePadName &&
      linkToUnlink.target.padName === targetPadName
    ) {
      this.links.splice(this.links.indexOf(linkToUnlink), 1)
    }
  }

  addComponent(component: Component) {
    this.components.push(component)
  }

  removeComponent(componentId: string) {
    const componentToRemove = this.getComponentById(componentId)
    if (!componentToRemove) {
      return
    }
    this.components.splice(this.components.indexOf(componentToRemove), 1)
  }

  getComponentsByType(type) {
    return this.components.filter((component) => component.type === type)
  }

  getComponentById(componentId) {
    return this.components.find(
      (component) => component.componentId === componentId
    )
  }

  getComponentByConnectedLinkTarget(componentId: string, padName: string) {
    const link = this.getLinkByTarget(componentId, padName)
    if (!link) {
      return null
    }
    return this.getComponentById(link.source.componentId)
  }

  getLinks(sourceComponentId, targetComponentId) {
    return this.links.filter((link) =>
      link.hasConnection(sourceComponentId, targetComponentId)
    )
  }

  getLinkBy(
    direction: 'source' | 'target',
    componentId: string,
    padName: string
  ) {
    return this.links.find(
      (link) =>
        link[direction].componentId === componentId &&
        link[direction].padName === padName
    )
  }

  getLinksByComponent(componentId: string, direction?: 'source' | 'target') {
    if (!direction) {
      return this.links.filter(
        (link) =>
          link.source.componentId === componentId ||
          link.target.componentId === componentId
      )
    }
    return this.links.filter(
      (link) => link[direction].componentId === componentId
    )
  }

  getLinkByTarget(targetComponentId: string, targetPadName: string) {
    return this.getLinkBy('target', targetComponentId, targetPadName)
  }

  getLinkBySource(sourceComponentId: string, sourcePadName: string) {
    return this.getLinkBy('source', sourceComponentId, sourcePadName)
  }

  getCandidateSourcePadsAvailableForConnection(componentId: string) {
    const component = this.getComponentById(componentId)
    if (!component) {
      return []
    }
    const components = this.components.filter(
      (c) => c.componentId !== componentId
    )
    // Check all links to see if they already have a link to this component
    const existingLinks = this.getLinksByComponent(componentId, 'target')

    // Filter out components that already have a link to this component
    const remainingComponents = components.filter((c) => {
      const existingLink = existingLinks.find(
        (link) => link.source.componentId === c.componentId
      )
      if (existingLink) {
        return false
      }
      return true
    })

    return remainingComponents
      .filter((c) => c.producesOutput())
      .map((c) => ({
        componentId: c.componentId,
        pads: c.outputs || [c.output],
      }))
  }
}
