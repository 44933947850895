import { RootState } from '@lib/store'
import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
  startOfMonth,
  startOfWeek,
  endOfDay,
  formatISO,
  getUnixTime,
} from 'date-fns'

interface DateRange {
  from: string
  to: string
}

interface UsageState {
  dateRange: DateRange
  distributionsDateRange: DateRange
}

const initialState: UsageState = {
  dateRange: {
    from: formatISO(startOfMonth(new Date())),
    to: formatISO(endOfDay(new Date())),
  },
  distributionsDateRange: {
    from: formatISO(startOfWeek(new Date())),
    to: formatISO(endOfDay(new Date())),
  },
}

const usageSlice = createSlice({
  name: 'usage',
  initialState,
  reducers: {
    setDateRange: (state, action: PayloadAction<DateRange>) => {
      state.dateRange = action.payload
    },
    setDistributionsDateRange: (state, action: PayloadAction<DateRange>) => {
      state.distributionsDateRange = action.payload
    },
  },
})

export const { setDateRange, setDistributionsDateRange } = usageSlice.actions
export default usageSlice.reducer

export const selectDateRange = (state: RootState) => state.usage.dateRange

export const selectDateRangeUnix = createSelector(
  [(state: RootState) => state.usage.dateRange],
  (
    dateRange
  ): {
    from: number
    to: number
  } => ({
    from: getUnixTime(new Date(dateRange.from)),
    to: getUnixTime(new Date(dateRange.to)),
  })
)

export const selectDistributionsDateRange = (state: RootState) =>
  state.usage.distributionsDateRange

export const selectDistributionsDateRangeDates = createSelector(
  [(state: RootState) => state.usage.distributionsDateRange],
  (
    distributionsDateRange
  ): {
    from: Date
    to: Date
  } => ({
    from: new Date(distributionsDateRange.from),
    to: new Date(distributionsDateRange.to),
  })
)
