import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ConnectionStateEnum } from '@hooks/useWebRTCMediaServer/mediasoupOrchestrator/ConnectionState'
import { RootState } from '@lib/store'

export enum LobbyConnectionState {
  Joined = 'joined',
  RequiresAuth = 'requiresAuth',
  AuthenticationFailed = 'authenticationFailed',
  Left = 'left',
  Inactive = 'inactive',
}

export type ConnectionState = LobbyConnectionState | ConnectionStateEnum

interface LobbyState {
  pinnedStreams: string[]
  isChatOpen: boolean
  newMessagesAvailable: boolean
  connectionState: ConnectionState
}

export const initialState: LobbyState = {
  pinnedStreams: [],
  isChatOpen: false,
  newMessagesAvailable: false,
  connectionState: ConnectionStateEnum.Idle,
}

const lobbySlice = createSlice({
  name: 'lobby',
  initialState,
  reducers: {
    setConnectionState: (state, action: PayloadAction<ConnectionState>) => {
      state.connectionState = action.payload
    },
    pinStream: (state, action: PayloadAction<string>) => {
      state.pinnedStreams = [...state.pinnedStreams, action.payload]
    },
    unpinStream: (state, action: PayloadAction<string>) => {
      state.pinnedStreams = state.pinnedStreams.filter(
        (streamId) => streamId !== action.payload
      )
    },
    toggleChatWindowVisibility: (state) => {
      state.isChatOpen = !state.isChatOpen
    },
    setNewMessagesAvailable: (state, action: PayloadAction<boolean>) => {
      state.newMessagesAvailable = action.payload
    },
  },
})

export const {
  setConnectionState,
  pinStream,
  unpinStream,
  toggleChatWindowVisibility,
  setNewMessagesAvailable,
} = lobbySlice.actions

export default lobbySlice.reducer

export const selectConnectionState = (state: RootState) =>
  state.lobby.connectionState
