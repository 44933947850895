import {
  createEntityAdapter,
  createSelector,
  createSlice,
} from '@reduxjs/toolkit'
import { RootState } from '@lib/store'
import { ConnectionStateEnum } from '@hooks/useWebRTCMediaServer/mediasoupOrchestrator/ConnectionState'
import { WorkflowSessionId } from '@customTypes/cloudmix/workflow'

export interface Stream {
  id: string
  peerId: string
  workflowSessionId: WorkflowSessionId
  connectionState: ConnectionStateEnum
}

const sliceAdapter = createEntityAdapter<Stream>({})

const ProjectWebRTCStreamSlice = createSlice({
  name: 'projectWebRTCStream',
  initialState: sliceAdapter.getInitialState(),
  reducers: {
    addStream: sliceAdapter.addOne,
    removeStream: sliceAdapter.removeOne,
    updateStream: sliceAdapter.updateOne,
    updateStreamConnectionState: (state, action) => {
      const { streamId, connectionState } = action.payload
      const stream = state.entities[streamId]
      if (stream) {
        stream.connectionState = connectionState
      }
    },
  },
})

export const {
  addStream,
  removeStream,
  updateStream,
  // updateStreamMediaStream,
  updateStreamConnectionState,
} = ProjectWebRTCStreamSlice.actions

const sliceSelectors = sliceAdapter.getSelectors<RootState>(
  (state) => state.projectWebRTCStream
)

export const selectStreams = sliceSelectors.selectAll

export const selectStreamById = (state: RootState, streamId: string) =>
  sliceSelectors.selectById(state, streamId)

export const selectStreamByPeerId = (state: RootState, peerId: string) =>
  sliceSelectors
    .selectAll(state)
    .filter((stream: Stream) => stream.peerId === peerId)

// Selects streams that are encoded by the engine
export const selectEngineStreamsByWorkflowSessionId = createSelector(
  [selectStreams, (state, workflowSessionId) => workflowSessionId],
  (streams, workflowSessionId) =>
    streams.filter(
      (stream) =>
        stream.workflowSessionId === workflowSessionId &&
        stream.peerId === workflowSessionId
    )
)

export const selectStreamByStreamIdAndWorkflowSessionId = (
  state: RootState,
  workflowSessionId: WorkflowSessionId,
  streamId?: string
) =>
  sliceSelectors
    .selectAll(state)
    .find(
      (stream: Stream) =>
        stream.workflowSessionId === workflowSessionId && stream.id === streamId
    )

export default ProjectWebRTCStreamSlice.reducer
