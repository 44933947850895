import { Component, ComponentData } from '@features/projects/lib/base/Component'
import { Pad, PadData } from '@features/projects/lib/base/Pad'
import { MixerPad, MixerPadData } from '@features/projects/lib/base/MixerPad'

/* eslint-disable react/sort-comp */
export interface MixerData extends ComponentData {
  inputs: MixerPadData[]
  output: PadData
}

export class Mixer extends Component {
  type = 'mixer'

  inputs: MixerPad[]

  output: Pad

  constructor(data: MixerData) {
    super(data)
    this.inputs = data.inputs
      .filter(Boolean)
      .map((input) => new MixerPad(input))
    this.output = new Pad(data.output)
  }

  addInput(name?: string, displayName?: string): MixerPad {
    const isFirstInput = this.inputs.length === 0
    const input = new MixerPad({
      name,
      displayName,
      ...(isFirstInput && {
        audio: {
          mute: true,
          volume: 1,
          stereoMix: {
            leftToRight: 0,
            leftToLeft: 1,
            rightToLeft: 0,
            rightToRight: 1,
          },
        },
      }),
    })

    this.inputs = [...this.inputs, input]

    return input
  }

  removeInput(name: string) {
    this.inputs = this.inputs.filter((input) => input.name !== name)
  }

  toData() {
    const commonData = super.toData()
    return {
      ...commonData,
      type: this.getType(),
      inputs: this.inputs.map((input) => input.toData()),
      output: this.output.toData(),
    }
  }

  static create() {
    const data = {
      inputs: [],
      output: new Pad(),
    }
    return new Mixer(data)
  }

  patch(data: Partial<MixerData>) {
    super.patch(data)
    const { inputs, output, ...rest } = data
    if (inputs && Array.isArray(inputs)) {
      this.inputs.forEach((input, index) => {
        if (inputs[index]) {
          input.patch(inputs[index])
        }
      })
    }
    this.output.patch(output as Pad)
    Object.assign(this, { ...rest })
  }
}
