import { getRootDirectoryId } from '@features/mediaLibrary/api/utils'
import {
  endOfMonth,
  formatISO,
  getUnixTime,
  startOfMonth,
  subDays,
} from 'date-fns'

export const migrations = {
  0: (state: any) => ({
    ...state,
  }),
  1: (state: any) => ({
    ...state,
    usage: {
      dateRange: {
        from: getUnixTime(startOfMonth(new Date())),
        to: getUnixTime(endOfMonth(new Date())),
      },
    },
  }),
  2: (state: any) => ({
    ...state,
    usage: {
      dateRange: {
        from: getUnixTime(startOfMonth(new Date())),
        to: getUnixTime(new Date()),
      },
      distributionsDateRange: {
        from: getUnixTime(subDays(new Date(), 7)),
        to: getUnixTime(new Date()),
      },
    },
  }),
  3: (state: any) => ({
    ...state,
    usage: {
      dateRange: {
        from: formatISO(startOfMonth(new Date())),
        to: formatISO(new Date()),
      },
      distributionsDateRange: {
        from: formatISO(subDays(new Date(), 7)),
        to: formatISO(new Date()),
      },
      distributions: [],
    },
  }),
  4: (state: any) => ({
    ...state,
    usage: {
      dateRange: {
        from: formatISO(startOfMonth(new Date())),
        to: formatISO(new Date()),
      },
      distributionsDateRange: {
        from: formatISO(subDays(new Date(), 7)),
        to: formatISO(new Date()),
      },
      distributions: [],
    },
    mediaLibrary: {
      ...state.mediaLibrary,
      openDirectoryId: getRootDirectoryId(),
      columns: [],
      selectedItems: [],
    },
  }),
}
